import { DownloadOutlined } from "@ant-design/icons";
import { Badge, Button, Col, DatePicker, Form, Input, Row, Select, Table } from "antd";
import { useGetFarmListQuery, useLazyGetFarmListQuery } from "api";
import { OBJ_COLOR } from "commons/constants";
import { CardComponent } from "components";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import { useExportExcel } from "hooks/useExportExcel";
import moment from "moment";
import numeral from "numeral";
import { useEffect, useMemo, useState } from "react";

interface Pagination {
	current: number;
	pageSize: number;
	total: number;
	showSizeChanger: boolean;
}

// const DATA = {
// 	totalPages: 35725,
// 	totalCount: 35725,
// 	items: [
// 		{
// 			id: 146675,
// 			datatype_name: "medicine",
// 			category_code: "M030000034",
// 			quantity: 1,
// 			unit: "GOI",
// 			source: "OUT",
// 			master_id: 84026,
// 			loss_code: null,
// 			username: "thai.nguyen",
// 			farm_id: 127,
// 			farm_code: "FV0013",
// 			farm_name: "Phú Thọ 1- Nhà D",
// 			bu_code: "VNM",
// 			created_date: "2023-03-15T00:49:35.000Z",
// 			doc_date: "2023-03-14",
// 			name: "Green Bio Lac_1kg",
// 		},
// 	],
// };
export const FarmReporting = () => {
	const [form] = Form.useForm();
	const [param, setParam] = useState({ pageNumber: 1, pageSize: 20 });
	const [search, setSearch] = useState({});
	const [pagination, setPagination] = useState<Pagination>();
	const [isLoadingButton, setIsLoadingButton] = useState(false);
	const { message, exportExcel } = useExportExcel();
	const [getFarmList] = useLazyGetFarmListQuery();

	const paramApi = useMemo(() => {
		const query = {
			...param,
		};
		if (!search) return query;

		return {
			...query,
			...search,
		};
	}, [param, search]);

	const { data: DATA, isLoading, isFetching } = useGetFarmListQuery(paramApi);

	useEffect(() => {
		if (!DATA) return;
		setPagination({
			current: param.pageNumber,
			pageSize: param.pageSize,
			total: DATA.totalCount,
			showSizeChanger: true,
		});
	}, [DATA, param]);

	const handleOnChangePagination = (value: any) => {
		setParam({ pageNumber: value.current, pageSize: value.pageSize });
	};
	const handleReset = () => {
		setSearch({});
		setParam((prev) => ({ ...prev, pageNumber: 1 }));
		form.resetFields();
	};

	const onFinish = (value: any) => {
		let paramSearch = Object.fromEntries(Object.entries(value).filter(([key, index]) => index));

		if (Object.prototype.hasOwnProperty.call(paramSearch, "from_date")) {
			paramSearch = { ...paramSearch, from_date: dayjs(paramSearch.from_date as string).format("YYYY-MM-DD") };
		}
		if (Object.prototype.hasOwnProperty.call(paramSearch, "to_date")) {
			paramSearch = { ...paramSearch, to_date: dayjs(paramSearch.to_date as string).format("YYYY-MM-DD") };
		}
		setSearch(paramSearch);
		setParam((prev) => ({ ...prev, pageNumber: 1 }));
	};

	const handleOnClickExport = async () => {
		setIsLoadingButton(true);

		try {
			const dataExport = await getFarmList({ ...search, exportExcel: 1 }).unwrap();

			const Heading: any = [
				{
					bu: "BU",
					farm_code: "Farm ID",
					farm_name: "Farm Name",
					doc_date: "Doc Date",
					code: "Code",
					name: "Name",
					quantity: "Quantity",
					uom: "UOM",
					datatype_name: "DataType",
					source: "Source",
					user: "User",
					created_date: "System date",
					id: "TransID",
				},
			];
			const wscols: any = [{ wch: 6 }, { wch: 12 }, { wch: 25 }, { wch: 10 }, { wch: 15 }, { wch: 25 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 10 }];
			const header: any[] = [];
			const data = dataExport.items.map((item, key) => {
				return {
					bu: item.bu_code,
					farm_code: item.farm_code,
					farm_name: item.farm_name,
					doc_date: dayjs(item.doc_date).format("DD/MM/YYYY"),
					source: item.source,
					user: item.username,
					created_date: dayjs(item.created_date).format("DD/MM/YYYY"),
					code: item.loss_code ? item.loss_code : item.category_code,
					name: item.name,
					quantity: item.quantity,
					uom: item.unit,
					datatype_name: item.datatype_name,
					id: item.master_id,
				};
			});
			exportExcel({ Heading, wscols, header, dataExport: data, nameFile: "farm-report" });
		} catch (error) {}
	};

	useEffect(() => {
		if (message !== "Success") return;

		setIsLoadingButton(false);
	}, [message]);

	return (
		<CardComponent
			title={"Farms Reporting"}
			extra={
				<Button loading={isLoadingButton} type="primary" onClick={handleOnClickExport} icon={<DownloadOutlined />}>
					Export
				</Button>
			}>
			<Form name="basic" form={form} onFinish={onFinish} style={{ width: "100%" }} layout="vertical">
				<Row gutter={[16, 0]} align="bottom">
					<Col xs={12} xl={5}>
						<Form.Item label="Farm ID, Farm code" name="farm_code">
							<Input placeholder="Farm ID, Farm code" />
						</Form.Item>
					</Col>
					<Col xs={12} xl={5}>
						<Form.Item label="From date" name="from_date">
							<DatePicker format={"DD-MM-YYYY"} style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col xs={12} xl={5}>
						<Form.Item label="To date" name="to_date">
							<DatePicker format={"DD-MM-YYYY"} style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col xs={12} xl={5}>
						<Form.Item label="Data Source" name="source">
							<Select
								style={{ width: "100%" }}
								options={[
									{ value: "IN", label: "IN" },
									{ value: "OUT", label: "OUT" },
									{ value: "OUT-TRAN", label: "OUT-TRAN" },
									{ value: "IN-TRAN", label: "IN-TRAN" },
									{ value: "CUT-OFF", label: "CUT-OFF" },
								]}
								allowClear
								placeholder="--- Select ---"
							/>
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="Data Type" name="datatype_name">
							<Select
								style={{ width: "100%" }}
								options={[
									{ value: "breed", label: "Breed" },
									{ value: "feed", label: "Feed" },
									{ value: "medicine", label: "Medicine" },
									{ value: "chickenloss", label: "Chicken Loss" },
								]}
								allowClear
								placeholder="--- Select ---"
							/>
						</Form.Item>
					</Col>
					{/* <Col span={6}>
						<Form.Item label="Product Code" name="category_id">
							<Input placeholder="Farm ID, Farm code" />
						</Form.Item>
					</Col> */}
					<Col xs={12} xl={5}>
						<Form.Item label="TransID From" name="trans_id_from">
							<Input placeholder="" />
						</Form.Item>
					</Col>
					<Col xs={12} xl={5}>
						<Form.Item label="TransID To" name="trans_id_to">
							<Input placeholder="" />
						</Form.Item>
					</Col>
					<Col xs={24} xl={5}>
						<Form.Item label="Area" name="area">
							<Select
								style={{ width: "100%" }}
								options={[
									{ value: "Miền Bắc", label: "Miền Bắc" },
									{ value: "Miền Nam", label: "Miền Nam" },
								]}
								allowClear
								placeholder="--- Select ---"
							/>
						</Form.Item>
					</Col>
					<Col xs={12} xl={5}>
						<Form.Item>
							<Button style={{ width: "100%" }} type="primary" htmlType="submit">
								Search
							</Button>
						</Form.Item>
					</Col>
					<Col xs={12} xl={4}>
						<Form.Item>
							<Button style={{ width: "100%", background: OBJ_COLOR.WARNING }} type="primary" onClick={handleReset}>
								Reset
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			<Table
				onChange={handleOnChangePagination}
				pagination={pagination}
				dataSource={DATA?.items.map((item, key) => ({ ...item, key: key + 1 }))}
				loading={isLoading || isFetching}
				scroll={{ x: true }}
				style={{ whiteSpace: "nowrap" }}
				// expandable={{
				// 	expandedRowRender: (record) => <TableReportingExpanded id={record.id} />,
				// }}
			>
				<Table.Column<DataFarmList>
					title="BU"
					render={(_, record) => {
						return record.bu_code;
					}}
				/>
				<Table.Column<DataFarmList>
					title="Farm Code"
					render={(_, record) => {
						return record.farm_code;
					}}
				/>
				<Table.Column<DataFarmList>
					title="Farm Name"
					render={(_, record) => {
						return record.farm_name;
					}}
				/>
				<Table.Column<DataFarmList>
					title="Date"
					render={(_, record) => {
						return record.doc_date;
					}}
				/>
				<Table.Column<DataFarmList>
					title="Code"
					render={(_, record) => {
						return record.loss_code ? record.loss_code : record.category_code;
					}}
				/>
				<Table.Column<DataFarmList>
					title="Name"
					render={(_, record) => {
						return record.name;
					}}
				/>
				<Table.Column<DataFarmList>
					title="Q.ty"
					render={(_, record) => {
						return numeral(record.quantity).format("0,0");
					}}
				/>
				<Table.Column<DataFarmList>
					title="UOM"
					render={(_, record) => {
						return record.unit;
					}}
				/>
				<Table.Column<DataFarmList>
					title="DataType"
					render={(_, record) => {
						return record.datatype_name;
					}}
				/>
				<Table.Column<DataFarmList>
					title="Source"
					render={(_, record) => {
						return record.source === "IN" ? (
							<Badge color={OBJ_COLOR.SUCCESS} count={record.source} />
						) : record.source === "OUT" ? (
							<Badge color={OBJ_COLOR.INFO} count={record.source} />
						) : record.source === "CUT-OFF" ? (
							<Badge color={OBJ_COLOR.DANGER} count={record.source} />
						) : record.source === "OUT-TRAN" ? (
							<Badge color={OBJ_COLOR.WARNING} count={record.source} />
						) : (
							<Badge color={"#18C2C3"} count={record.source} />
						);
					}}
				/>
				<Table.Column<DataFarmList>
					title="User"
					render={(_, record) => {
						return record.username;
					}}
				/>
				<Table.Column<DataFarmList>
					title="System date"
					render={(_, record) => {
						return moment(record.created_date).format("DD/MM/YYYY HH:mm");
					}}
				/>
				<Table.Column<DataFarmList>
					title="ID"
					render={(_, record) => {
						return record.master_id;
					}}
				/>
				<Table.Column<DataFarmList>
					title="Farm ID"
					render={(_, record) => {
						return record.farm_id;
					}}
				/>
			</Table>
		</CardComponent>
	);
};
