import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type State = UserAuth | null;

export const user = createSlice({
	name: "user",
	initialState: null as State,
	reducers: {
		setUser: (state, action: PayloadAction<UserAuth>) => action.payload,
		removeUser: () => {
			localStorage.removeItem("persist:root");
			return null;
		},
	},
});

export const { setUser, removeUser } = user.actions;

export const userReducer = user.reducer;
