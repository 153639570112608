import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { Header } from "./components/header";

const Wrapper = styled.div`
	min-height: 100vh;
	padding: 35px;
	@media (max-width: 750px) {
		padding: 24px 12px;
	}
`;

export const Layout = (): JSX.Element => {
	return (
		<>
			<Header />
			<Wrapper>
				<Outlet />
			</Wrapper>
		</>
	);
};
