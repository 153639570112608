import { useNavigate } from "react-router-dom";

import { LoginOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Form, Image, Input, Row, Space, Typography, notification } from "antd";
import { useLoginMutation } from "api";

import Logo from "assets/images/download.png";
import { useAppDispatch } from "store";
import styled from "styled-components";
import { setUser } from "slice";
import { useState } from "react";

const { Title } = Typography;

const WrapperBoxLogin = styled.div`
	height: 100vh;
	display: flex;
	justify-content: center;
	align-content: center;
`;
const CardLoginCustom = styled(Card)`
	box-shadow: rgb(0 0 0 / 16%) 0px 1px 2px -2px, rgb(0 0 0 / 12%) 0px 3px 6px 0px, rgb(0 0 0 / 9%) 0px 5px 12px 4px;
	background-size: cover;
	margin: auto;
	height: 450px;
	width: 400px;
	border-color: transparent;
`;

export const Login = () => {
	const dispatch = useAppDispatch();

	const navigate = useNavigate();
	const [login] = useLoginMutation();
	const [isLoading, setIsLoading] = useState(false);
	const onFinish = async (values: { userId: string; password: string }) => {
		setIsLoading(true);
		try {
			const data = await login(values).unwrap();
			dispatch(setUser(data));
			navigate("/", { replace: true });
		} catch (error: any) {
			setIsLoading(false);
			notification["error"]({
				message: "Tài khoản hoặc mật khẩu không đúng",
			});
		}
	};

	return (
		<WrapperBoxLogin>
			<CardLoginCustom>
				<Row gutter={[0, 8]} justify="center">
					<Col xs={20} style={{ textAlign: "center" }}>
						<Space align="center" style={{ marginBottom: 5 }}>
							<Image width={90} src={Logo} preview={false} />
						</Space>
					</Col>
					<Col xs={20}>
						<Divider>
							<Title style={{ color: "rgb(0 65 36)", marginBottom: 0 }} level={5}>
								<span style={{ color: "#40ad48" }}>G LINKFARM </span>
							</Title>
						</Divider>
					</Col>
					<Col xs={20}>
						<Form layout="vertical" name="basic" onFinish={onFinish}>
							<Form.Item required label="Username" name="username" rules={[{ required: true, message: "Please input your username!" }]}>
								<Input suffix={<UserOutlined />} placeholder="Username" />
							</Form.Item>
							<Form.Item style={{ marginBottom: 45 }} required label="Password" name="password" rules={[{ required: true, message: "Please input your password!" }]}>
								<Input.Password placeholder="Password" />
							</Form.Item>
							<Form.Item>
								<Button loading={isLoading} icon={<LoginOutlined />} style={{ width: "100%" }} type="primary" htmlType="submit">
									Login
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</CardLoginCustom>
		</WrapperBoxLogin>
	);
};
