import { Alert, Badge, Button, Card, Checkbox, Col, DatePicker, Drawer, Form, notification, Row, Select, Space, Spin } from "antd";
import { useCreateUserFarmMutation, useDeleteUserFarmMutation, useGetFarmsQuery, useLazyGetUserFarmQuery } from "api";
import { OBJ_COLOR } from "commons/constants";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

interface AssignFarmDrawerProps {
	visible: boolean;
	setVisible: (visible: boolean) => void;
	// id: number;
	user_id: number;
}
export const AssignFarmDrawer = ({ visible, setVisible, user_id }: AssignFarmDrawerProps) => {
	const [form] = Form.useForm();
	const [deleteUserFarm] = useDeleteUserFarmMutation();
	const [createUserFarm] = useCreateUserFarmMutation();
	const [getUserFarm, { data: DATA, isLoading }] = useLazyGetUserFarmQuery();
	const { data } = useGetFarmsQuery({ pageSize: 1000, active: true });
	const [dataAssign, setDataAssign] = useState<UserFarm[]>([]);

	useEffect(() => {
		if (!visible) return;
		form.resetFields();
	}, [visible, form]);

	useEffect(() => {
		const run = async () => {
			if (!user_id) return;
			form.resetFields();
			setDataAssign(await getUserFarm({ user_id }).unwrap());
		};
		run();
	}, [user_id, getUserFarm, form]);
	useEffect(() => {
		if (!DATA) return;

		setDataAssign(DATA);
	}, [DATA]);

	const formatTime = (time: any) => {
		return dayjs(time as string).format("YYYY-MM-DD");
	};

	const onFinish = async (value: any) => {
		const active = !value.active ? false : value.active;
		try {
			await createUserFarm({ user_id, farm_code: value.farm_code, start_date: formatTime(value.start_date), end_date: formatTime(value.end_date), active }).unwrap();
			notification.success({
				message: "Success",
			});
		} catch (e: any) {
			notification.error({
				message: e.data.message,
			});
		}
	};

	const handleClose = async (value: number) => {
		try {
			const data = [...dataAssign];

			const index = data.findIndex((item) => item.id === value);

			if (index === -1) return;

			data.splice(index, 1);
			setDataAssign(data);

			await deleteUserFarm(value).unwrap();
			notification.success({
				message: "Success",
			});
		} catch (e: any) {
			notification.error({
				message: e.data.message,
			});
		}
	};

	return (
		<>
			<Drawer width={window.innerWidth > 1000 ? "80%" : "auto"} title="Assign farm" placement="right" onClose={() => setVisible(false)} open={visible}>
				<Row gutter={[16, 16]}>
					<Col xs={24} xl={12}>
						<Card size="small" title="Form Assign" style={{ width: "100%" }}>
							<Form layout="vertical" onFinish={onFinish} form={form} autoComplete="off">
								<Form.Item label="Select Farm" name="farm_code" rules={[{ required: true, message: "Please input your username!" }]}>
									<Select
										showSearch
										style={{ width: "100%" }}
										options={data?.items.map((item, key) => ({ key: key, label: `${item?.farm_code} ${item?.farm_name ? `- ${item?.farm_name}` : ""} `, value: item.farm_code }))}
										allowClear
										placeholder="---Seclect Item---"></Select>
								</Form.Item>
								<Form.Item label="Form Date" name="start_date" rules={[{ required: true, message: "Please input your Form Date!" }]}>
									<DatePicker style={{ width: "100%" }} format={"YYYY-MM-DD"} />
								</Form.Item>
								<Form.Item label="End Date" name="end_date" rules={[{ required: true, message: "Please input your End Date!" }]}>
									<DatePicker style={{ width: "100%" }} format={"YYYY-MM-DD"} />
								</Form.Item>
								<Space align="baseline">
									Active
									<Form.Item name="active" valuePropName="checked">
										<Checkbox />
									</Form.Item>
								</Space>
								<Form.Item wrapperCol={{ offset: 10, span: 4 }}>
									<Button type="primary" htmlType="submit">
										Submit
									</Button>
								</Form.Item>
							</Form>
						</Card>
					</Col>
					<Col xs={24} xl={12}>
						<Card
							size="small"
							title={
								<Space>
									Assign Farm
									<Badge count={DATA?.length || 0} showZero color={OBJ_COLOR.SUCCESS} />
								</Space>
							}
							style={{ width: "100%" }}>
							<Space direction="vertical" size={0} style={{ width: "100%", maxHeight: "calc(100vh - 170px)", overflow: "auto", justifyItems: "center" }}>
								{isLoading ? (
									<Spin />
								) : (
									dataAssign?.map((item) => <Alert onClose={() => handleClose(item.id)} key={item.id} message={`${item.farm_code} - ${item?.farm?.farm_name}`} type="info" closable style={{ marginBottom: 10 }} />)
								)}
							</Space>
						</Card>
					</Col>
				</Row>
			</Drawer>
		</>
	);
};
