import { useDebounce } from "hooks/useDebounce";
import { useEffect, useState } from "react";

const useSelect = <T>(fn: Function, data?: T[]) => {
	const [isFetching, setIsFetching] = useState(false);

	const [searchValue, setSearchValue] = useState<string>("");

	const debouncedValue = useDebounce(searchValue);

	const [options, setOptions] = useState<T[]>([]);

	useEffect(() => {
		fn({
			keyword: debouncedValue,
		});
	}, [debouncedValue]);

	useEffect(() => {
		if (!data) return;

		setIsFetching(false);
		setOptions(data);
	}, [data]);

	const handleOnSearch = (value: string) => {
		setOptions([]);
		setSearchValue(value);
		setIsFetching(true);
	};

	const handleOnClear = () => {
		if (searchValue) {
			setOptions([]);
		}
		setSearchValue("");
	};

	return {
		options,
		setOptions,
		setSearchValue,
		handleOnSearch,
		handleOnClear,
		searchValue,
		isFetching,
	};
};

export default useSelect;
