import { api, providesList } from ".";
import qs from "query-string";

const url = "/master-sell";

const sellApi = api.injectEndpoints({
	endpoints: (build) => ({
		getDetailSell: build.query<SellReportPage, Record<string, any> | undefined>({
			query: (query) => {
				if (query) {
					return `detail-sell?${qs.stringify(query)}`;
				}
				return url;
			},
			providesTags: (result) => providesList(result?.items, "master-sell"),
		}),
		getDetailSellVoiTrans: build.query<SellTransPage, Record<string, any> | undefined>({
			query: (query) => {
				if (query) {
					return `${url}/void-trans?${qs.stringify(query)}`;
				}
				return url;
			},
			providesTags: (result) => providesList(result?.items, "sell-trans"),
		}),
		createMasterSellReport: build.mutation<void, Record<string, any>>({
			query: (data) => {
				return {
					url: `${url}`,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: (result, error, { id }) => {
				return error ? [] : [{ type: "master-sell", id }];
			},
		}),
		sellTrans: build.mutation<void, Record<string, any>>({
			query: (data) => {
				const { id } = data;
				return {
					url: `${url}/void-trans/${id}`,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: (result, error, { id }) => {
				return error
					? []
					: [
							{ type: "sell-trans", id: "LIST" },
							{ type: "master-sell", id: "LIST" },
					  ];
			},
		}),
		sellRecall: build.mutation<void, Record<string, any>>({
			query: (data) => {
				const { id } = data;
				return {
					url: `${url}/recall/${id}`,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: (result, error, { id }) => {
				return error ? [] : [{ type: "sell-trans", id }];
			},
		}),
	}),
});
export const {
	useGetDetailSellQuery,
	useGetDetailSellVoiTransQuery,
	useLazyGetDetailSellQuery,
	useLazyGetDetailSellVoiTransQuery,
	useSellTransMutation,
	useSellRecallMutation,
	useCreateMasterSellReportMutation,
} = sellApi;
