import useFormToggle from "hooks/useFormToggle";
import HistoryScaleDrawer from "pages/sales-order/components/HistoryScaleDrawer";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const ScaleHistory = () => {
	const navigate = useNavigate();

	const [searchParams] = useSearchParams();

	const [salesOrderId, setSalesOrderId] = useState<number>(0);
	const [salesOrderDetailId, setSalesOrderDetailId] = useState<number>(0);

	const { open, onOpen, onClose } = useFormToggle();

	useEffect(() => {
		const salesOrderId = Number(searchParams.get("salesOrderId")) || 0;
		const salesOrderDetailId = Number(searchParams.get("salesOrderDetailId")) || 0;

		if (!salesOrderId || !salesOrderDetailId) return navigate("/sales-order");

		setSalesOrderId(salesOrderId);
		setSalesOrderDetailId(salesOrderDetailId);
		onOpen();
	}, [searchParams]);

	return <HistoryScaleDrawer open={open} onClose={onClose} salesOrderId={salesOrderId} salesOrderDetailId={salesOrderDetailId} />;
};

export default ScaleHistory;
