import { CheckOutlined, CloseOutlined, MinusCircleOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Divider, Drawer, Empty, Flex, Form, Image, Input, InputNumber, Row, Select, Spin, Typography, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useLazyGetCategoriesBreedInByFarmQuery, useLazyGetFarmsForSalesOrderQuery } from "api";
import { useLazyGetCustomerShippingQuery, useLazyGetCustomersQuery } from "api/sales-order/customer-api";
import { useCreateSalesOderMutation, useGetOneSalesOrderQuery, useGetSalesOrderDetailQuery, useUpdateSalesOderMutation } from "api/sales-order/sales-order-api";
import { OBJ_COLOR } from "commons/constants";
import { StatusSalesOrder } from "commons/constants/sales-order";
import { getUrlImage, handleSelectFilter, onKeyPress } from "commons/constants/util";
import dayjs from "dayjs";
import useSelect from "hooks/useSelect";
import { useEffect, useState } from "react";
import AddImage from "./AddImage";

export interface FormSalesOrderModal {
	custPhone: string;
	customerId: string;
	driver: string;
	driverPhone: string;
	farmCode: string;
	orderDate: dayjs.Dayjs;
	shipDate: dayjs.Dayjs;
	shipAddress: string;
	salesOderDetail: {
		itemCode: string;
		femaleQuantity: number;
		itemType: number;
		maleQuantity: number;
		noneQuantity: number;
	}[];
}

const salesOderDetailDefault = [
	{
		itemCode: undefined,
	},
];

interface SalesOrderModalProps {
	open: boolean;
	onClose: () => void;
	salesOrderId: number;
}

const SalesOrderModal = ({ open, onClose, salesOrderId }: SalesOrderModalProps) => {
	const [form] = Form.useForm<FormSalesOrderModal>();

	const [getCustomers, { data: dataGetCustomers }] = useLazyGetCustomersQuery();
	const {
		options: optionsCustomers,
		handleOnSearch: handleOnSearchCustomers,
		handleOnClear: handleOnClearCustomers,
		isFetching: isFetchingGetCustomers,
	} = useSelect<Customer>(getCustomers, dataGetCustomers);

	const [getFarmsForSalesOrder, { data: dataFarms }] = useLazyGetFarmsForSalesOrderQuery();
	const { options: optionsFarms, handleOnSearch: handleOnSearchFarms, handleOnClear: handleOnClearFarms, isFetching: isFetchingGetFarms } = useSelect<FarmsReport>(getFarmsForSalesOrder, dataFarms);

	const [getCustomerShipping, { data: dataCustomerShipping }] = useLazyGetCustomerShippingQuery();

	const [getCategoriesBreedInByFarm, { data: dataBreedInByFarm }] = useLazyGetCategoriesBreedInByFarmQuery();

	const [createSalesOder, { isLoading: isLoadingCreateSalesOder }] = useCreateSalesOderMutation();
	const [updateSalesOder, { isLoading: isLoadingUpdateSalesOder }] = useUpdateSalesOderMutation();

	const [orderDate, setOrderDate] = useState<dayjs.Dayjs>(dayjs());

	const [status, setStatus] = useState("");
	const [pathImage, setPathImage] = useState<string[]>([]);

	const { data: dataSalesOrder } = useGetOneSalesOrderQuery(salesOrderId, { skip: !salesOrderId, refetchOnMountOrArgChange: true });
	const { data: dataSalesOrderDetail } = useGetSalesOrderDetailQuery(salesOrderId, { skip: !salesOrderId, refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (!open) return;

		if (!dataSalesOrder || !dataSalesOrderDetail || !salesOrderId) {
			form.resetFields();
			form.setFieldsValue({
				salesOderDetail: salesOderDetailDefault,
			});
			return;
		}

		const { orderDate, shipDate, pathImage } = dataSalesOrder;

		form.setFieldsValue({ ...dataSalesOrder, orderDate: dayjs(orderDate), shipDate: dayjs(shipDate) });
		form.setFieldsValue({ salesOderDetail: dataSalesOrderDetail });

		getCategoriesBreedInByFarm({ farmCode: dataSalesOrder.farmCode });

		setPathImage(pathImage.split("|"));
	}, [open, dataSalesOrder, dataSalesOrderDetail]);

	useEffect(() => {
		if (!dataCustomerShipping?.length) return;

		form.setFieldValue("shipAddress", dataCustomerShipping[0].cusAddress);
	}, [dataCustomerShipping]);

	const handleOnFinish = async (values: FormSalesOrderModal) => {
		// if (status === StatusSalesOrder.Submit && !pathImage.length) {
		// 	return notification.error({
		// 		message: "Vui lòng thêm hình ảnh trước khi xác nhận đơn hàng.",
		// 	});
		// }

		try {
			const { customerId, orderDate, shipDate, salesOderDetail, ...rest } = values;

			const post = {
				customerId,
				customerName: dataGetCustomers?.find((item) => item.customerId === customerId)?.customerName,
				orderDate: dayjs(orderDate).toISOString(),
				shipDate: dayjs(shipDate).toISOString(),
				status,
				pathImage: pathImage.join("|"),
				...rest,
				salesOderDetail: salesOderDetail.map((item) => {
					return {
						...item,
						itemName: dataBreedInByFarm?.find((category) => category.code === item.itemCode)?.name,
					};
				}),
			};

			if (!salesOrderId) {
				await createSalesOder(post).unwrap();
			} else {
				await updateSalesOder({
					...post,
					id: salesOrderId,
				}).unwrap();
			}

			onClose();
			notification.success({
				message: `${!salesOrderId ? "Tạo mới" : "Cập nhập"} đơn hàng thành công !`,
			});
		} catch (error: any) {
			notification.error({
				message: error?.data?.message,
			});
		}
	};

	const handleOnSave = () => {
		setStatus(StatusSalesOrder.Draft);
		form.submit();
	};

	const handleOnConfirm = () => {
		setStatus(StatusSalesOrder.Submit);
		form.submit();
	};

	return (
		<Drawer
			title={`${!salesOrderId ? "Tạo mới" : "Cập nhập"} đơn hàng`}
			open={open}
			width={1000}
			onClose={onClose}
			footer={
				<Flex gap={10} justify="flex-end">
					<Button disabled={isLoadingCreateSalesOder || isLoadingUpdateSalesOder} onClick={onClose} icon={<CloseOutlined />}>
						Huỷ bỏ
					</Button>
					<Button loading={isLoadingCreateSalesOder || isLoadingUpdateSalesOder} type="primary" onClick={handleOnSave} ghost icon={<SaveOutlined />}>
						Lưu
					</Button>
					<Button loading={isLoadingCreateSalesOder || isLoadingUpdateSalesOder} type="primary" onClick={handleOnConfirm} icon={<CheckOutlined />}>
						Xác nhận
					</Button>
				</Flex>
			}>
			<Form form={form} onFinish={handleOnFinish} layout="vertical">
				<Row gutter={[40, 0]}>
					<Col span={12}>
						<Form.Item label="Ngày tạo" name="orderDate" initialValue={orderDate} rules={[{ required: true, message: "Chọn ngày tạo" }]}>
							<DatePicker
								disabled
								onChange={(value) => {
									if (!value) return;
									form.setFieldValue("shipDate", value);
									setOrderDate(value);
								}}
								format={"DD-MM-YYYY"}
								disabledDate={(date) => !date || date.isBefore(dayjs())}
								style={{ width: "100%" }}
							/>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label="Trại" name="farmCode" rules={[{ required: true, message: "Chọn trại" }]}>
							<Select
								placeholder="Chọn"
								showSearch
								allowClear
								// onSearch={handleOnSearchFarms}
								filterOption={handleSelectFilter}
								onClear={handleOnClearFarms}
								notFoundContent={isFetchingGetFarms ? <Spin size="small" /> : <Empty />}
								onChange={(value) => {
									if (!value) return;

									getCategoriesBreedInByFarm({ farmCode: value });

									form.setFieldsValue({
										salesOderDetail: salesOderDetailDefault,
									});
								}}
								options={optionsFarms?.map((item, key: number) => ({ label: `[${item.farm_code}]: ${item.farm_name}`, value: item.farm_code }))}
							/>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label="Khách hàng" name="customerId" rules={[{ required: true, message: "Chọn khách hàng" }]}>
							<Select
								placeholder="Chọn"
								showSearch
								allowClear
								onSearch={handleOnSearchCustomers}
								filterOption={false}
								onClear={handleOnClearCustomers}
								notFoundContent={isFetchingGetCustomers ? <Spin size="small" /> : <Empty />}
								onChange={(value) => {
									const customer = dataGetCustomers?.find((item) => item.customerId === value);

									if (!customer) return;

									const { customerId, custPhone, shipAddress } = customer;

									form.setFieldValue("custPhone", custPhone);
									form.resetFields(["shipAddress"]);

									getCustomerShipping(customerId);
								}}>
								{optionsCustomers?.map((item, key: number) => (
									<Select.Option key={key} value={item.customerId}>
										{`[${item.customerId}]: ${item.customerName}`}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label="SĐT khách hàng" name="custPhone" rules={[{ required: true, message: "Nhập SĐT khách hàng" }]}>
							<Input placeholder="SĐT khách hàng" onKeyPress={onKeyPress} />
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label="Ngày giao hàng" name="shipDate" initialValue={dayjs()} rules={[{ required: true, message: "Chọn ngày giao hàng" }]}>
							<DatePicker format={"DD-MM-YYYY"} disabledDate={(date) => !date || date.isBefore(orderDate)} style={{ width: "100%" }} />
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label="Địa chỉ giao hàng" name="shipAddress" rules={[{ required: true, message: "Nhập địa chỉ" }]}>
							{dataCustomerShipping?.length ? (
								<Select
									placeholder="Chọn"
									showSearch
									allowClear
									filterOption={handleSelectFilter}
									options={dataCustomerShipping.map((item) => ({
										label: item.cusAddress,
										value: item.cusAddress,
									}))}
								/>
							) : (
								<TextArea placeholder="Địa chỉ giao hàng" rows={1} />
							)}
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label="Tên tài xế" name="driver">
							<Input placeholder="Tên tài xế" />
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label="SĐT tài xế" name="driverPhone">
							<Input placeholder="SĐT tài xế" onKeyPress={onKeyPress} />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Số xe" name="truckId">
							<Input placeholder="Số xe" />
						</Form.Item>
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<AddImage setPathImage={(path: string) => setPathImage([...pathImage, path])} />
						<Flex gap={20} wrap="wrap" style={{ marginTop: 10 }}>
							{pathImage
								.filter((src) => src)
								.map((src) => (
									<Image style={{ border: "1px solid rgb(239, 239, 239)", borderRadius: 6 }} width={100} height={100} src={getUrlImage(src)} />
								))}
						</Flex>
					</Col>
				</Row>

				<Divider />

				<Row>
					<Col span={24}>
						<Form.List name="salesOderDetail">
							{(fields, { add, remove }) => (
								<>
									<Form.Item>
										<Flex justify="space-between" align="center">
											<Typography.Title level={5} style={{ color: OBJ_COLOR.INFO }}>
												CON GIỐNG
											</Typography.Title>
											<Button style={{ width: 40, height: 40, borderColor: OBJ_COLOR.INFO }} type="dashed" onClick={() => add()} block icon={<PlusOutlined style={{ color: OBJ_COLOR.INFO }} />} />
										</Flex>
									</Form.Item>
									{fields.map(({ key, name, ...restField }, index) => (
										<Row key={key} gutter={10}>
											<Col span={8}>
												<Form.Item {...restField} name={[name, "itemCode"]} label={index === 0 && "Con giống"} rules={[{ required: true, message: "Chọn con giống" }]}>
													<Select
														style={{ width: "100%" }}
														placeholder="Chọn"
														showSearch
														allowClear
														filterOption={handleSelectFilter}
														options={dataBreedInByFarm?.map((item) => ({
															label: `[${item.code}]: ${item.name}`,
															value: item.code,
														}))}
													/>
												</Form.Item>
											</Col>
											<Col span={3}>
												<Form.Item {...restField} name={[name, "chickenType"]} label={index === 0 && "Loại"} rules={[{ required: true, message: "Chọn Loại" }]}>
													<Select
														style={{ width: "100%" }}
														options={[
															{ value: 1, label: "1" },
															{ value: 2, label: "2" },
														]}
														allowClear
														placeholder="chọn"
													/>
												</Form.Item>
											</Col>
											<Col span={4}>
												<Form.Item {...restField} name={[name, "maleQuantity"]} required label={index === 0 && "Số lượng trống"} rules={[{ required: true, message: "Nhập số lượng trống" }]} initialValue={0}>
													<InputNumber style={{ width: "100%" }} placeholder="Số lượng trống" min={0} controls={false} />
												</Form.Item>
											</Col>
											<Col span={4}>
												<Form.Item {...restField} name={[name, "femaleQuantity"]} required label={index === 0 && "Số lượng mái"} rules={[{ required: true, message: "Nhập số lượng mái" }]} initialValue={0}>
													<InputNumber style={{ width: "100%" }} placeholder="Số lượng mái" min={0} controls={false} />
												</Form.Item>
											</Col>
											<Col span={4}>
												<Form.Item {...restField} name={[name, "noneQuantity"]} required label={index === 0 && "Số lượng xô"} rules={[{ required: true, message: "Nhập số lượng xô" }]} initialValue={0}>
													<InputNumber style={{ width: "100%" }} placeholder="Số lượng xô" min={0} controls={false} />
												</Form.Item>
											</Col>
											<Col span={1}>{fields.length > 1 ? <MinusCircleOutlined style={{ color: OBJ_COLOR.DANGER }} onClick={() => remove(name)} /> : null}</Col>
										</Row>
									))}
								</>
							)}
						</Form.List>
					</Col>
				</Row>
			</Form>
		</Drawer>
	);
};

export default SalesOrderModal;
