import { useState } from "react";
import styled from "styled-components";
import { Logo } from "./Logo";
import { NavLink } from "./NavLink";
import NavLinkMobile from "./NavLinkMobile";
import { SectionRight } from "./SectionRight";
// import { NavLink } from "./Navlink";

const Wrapper = styled.div`
	padding: 0 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: solid 1px #e8e8e8;
	overflow: auto;
	box-shadow: 0 0 30px #f3f1f1;

	.nav-link {
		width: 100%;
		min-width: 200px;
		display: block;
	}

	.nav-link-mobile {
		display: none;
	}

	.logo {
		display: block;
	}

	.nav-left {
		display: flex;
		align-items: center;

		.switch-language {
			display: block;
			margin-right: 20px;
		}

		.switch-language,
		.info {
			cursor: pointer;
		}
	}

	@media (max-width: 750px) {
		.nav-left {
			.switch-language {
				display: none;
			}
		}

		.nav-link {
			display: none;
		}

		.nav-link-mobile {
			display: block;
		}
	}
`;

const MenuLeft = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

export const Header = () => {
	const [isVisible, setIsVisible] = useState(false);

	return (
		<Wrapper>
			<div className="nav-link-mobile">
				<NavLinkMobile isVisible={isVisible} setIsVisible={setIsVisible}>
					<NavLink mode="inline" setIsVisible={setIsVisible} />
				</NavLinkMobile>
			</div>
			<MenuLeft>
				<div className="logo">
					<Logo />
				</div>
				<div className="nav-link">
					<NavLink setIsVisible={setIsVisible} />
				</div>
			</MenuLeft>
			<SectionRight />
		</Wrapper>
	);
};
