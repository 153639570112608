import { useEffect, useState } from "react";

const usePaginated = () => {
	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [params, setParams] = useState<{ pageNumber?: number; pageSize?: number; salesOrderId?: number; farmCode?: string; customerId?: string; startDate?: string; endDate?: string }>({});

	useEffect(() => {
		setParams({ pageNumber, pageSize });
	}, [pageNumber, pageSize]);

	return { params, setParams, pageNumber, setPageNumber, pageSize, setPageSize };
};

export default usePaginated;
