import { Checkbox, Form, Input, Modal, Space, notification } from "antd";
import { useCreateCategoriesMutation, useUpdateCategoriesMutation } from "api";
import { useEffect } from "react";

interface ReportModalProps {
	visible: boolean;
	dataModal?: DataCategoriesType;
	setVisible: (visible: boolean) => void;
	title: string;
	datatype_id?: number;
}
export const CategoriModel = ({ title, visible, setVisible, dataModal, datatype_id }: ReportModalProps) => {
	const [form] = Form.useForm();
	const [createCategories, { isLoading }] = useCreateCategoriesMutation();
	const [updateCategories, { isLoading: isLoadingUpadte }] = useUpdateCategoriesMutation();

	useEffect(() => {
		if (!visible) return;

		form.resetFields();

		if (dataModal) {
			form.setFieldsValue({
				code: dataModal.code,
				name: dataModal.name,
				uom: dataModal.uom,
				active: dataModal.active,
			});
		}
	}, [visible, form, dataModal]);

	const handleOk = () => {
		form.submit();
	};

	const onFinish = async (value: any) => {
		const active = !value.active ? false : value.active;
		try {
			if (!dataModal) {
				await createCategories({ ...value, active, datatype_id }).unwrap();
			} else {
				await updateCategories({ ...value, active, datatype_id, id: dataModal?.id }).unwrap();
			}
			setVisible(false);
			notification.success({
				message: "Success",
			});
		} catch (e: any) {
			notification.error({
				message: e.data.message,
			});
		}
	};

	return (
		<Modal maskClosable={false} title={title} open={visible} onOk={handleOk} okText="Submit" onCancel={() => setVisible(false)} confirmLoading={isLoading || isLoadingUpadte}>
			<Form
				form={form}
				layout="vertical"
				onFinish={onFinish}
				// onFinishFailed={onFinishFailed}
				autoComplete="off">
				<Form.Item label="Code" name="code" rules={[{ required: true, message: "Please input your code!" }]}>
					<Input placeholder="Nhập code" />
				</Form.Item>
				<Form.Item label="Name" name="name" rules={[{ required: true, message: "Please input your name!" }]}>
					<Input placeholder="Nhập tên" />
				</Form.Item>
				<Form.Item label="UOM" name="uom" rules={[{ required: true, message: "Please input your uom!" }]}>
					<Input placeholder="Nhập uom" />
				</Form.Item>
				<Space align="baseline">
					Active
					<Form.Item name="active" valuePropName="checked">
						<Checkbox />
					</Form.Item>
				</Space>
			</Form>
		</Modal>
	);
};
