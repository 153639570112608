import dayjs from "dayjs";
import numeral from "numeral";
import { KeyboardEvent } from "react";
import { BASE_URL_API } from ".";

const stringToSlug = (str: string) => {
	var from = "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ",
		to = "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy";
	for (var i = 0, l = from.length; i < l; i++) {
		str = str.replace(RegExp(from[i], "gi"), to[i]);
	}

	str = str
		.toLowerCase()
		.trim()
		.replace(/[^a-z0-9\-]/g, "-")
		.replace(/-+/g, "-");

	return str;
};

export const dateFormat = (str: string | dayjs.Dayjs, full?: boolean) => (!full ? dayjs(str).format("DD/MM/YYYY") : dayjs(str).format("DD/MM/YYYY HH:mm:ss"));
export const numberFormat = (value: number) => numeral(value).format("0,0");

export const onKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
	if (!/[0-9]/.test(event.key)) {
		event.preventDefault();
	}
};

export const handleSelectFilter = (input: string, { label }: any) => {
	return stringToSlug(label).indexOf(stringToSlug(input)) >= 0;
};


export const getUrlImage = (str: string) => `${BASE_URL_API}/${str}`