import qs from "query-string";
import { api, providesList } from ".";

const url = "/farms";

const categoriesApi = api.injectEndpoints({
	endpoints: (build) => ({
		getFarms: build.query<Farms, Record<string, any> | undefined>({
			query: (query) => {
				if (query) {
					return `${url}?${qs.stringify(query)}`;
				}
				return url;
			},
			providesTags: (result) => providesList(result?.items, "farm"),
		}),
		getFarmsReport: build.query<FarmsReport[], Record<string, any> | undefined>({
			query: (query) => {
				return `${url}/list-acc`;
			},
			providesTags: (result) => providesList(result, "farm-report"),
		}),
		createFarms: build.mutation<void, Record<string, any>>({
			query: (data) => {
				return {
					url,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: (result, error, { id }) => {
				return error ? [] : [{ type: "farm", id }];
			},
		}),
		updateFarms: build.mutation<void, Record<string, any>>({
			query: (body) => {
				const { id } = body;
				return {
					url: `${url}/${id}`,
					method: "PUT",
					body,
				};
			},
			invalidatesTags: (result, error, { id }) => {
				return error ? [] : [{ type: "farm", id }];
			},
		}),
		updateRecycleFarms: build.mutation<void, Record<string, any>>({
			query: (body) => {
				const { id } = body;
				return {
					url: `${url}/recycle/${id}`,
					method: "PUT",
					body,
				};
			},
			invalidatesTags: (result, error, { id }) => {
				return error ? [] : [{ type: "farm", id }];
			},
		}),
		deleteFarms: build.mutation<void, number | undefined>({
			query: (id) => {
				return {
					url: `${url}/${id}`,
					method: "DELETE",
				};
			},
			invalidatesTags: (result, error) => {
				return error ? [] : [{ type: "farm", id: "LIST" }];
			},
		}),
		getFarmsForSalesOrder: build.query<FarmsReport[], Record<string, any> | void>({
			query: (query) => {
				if (query) {
					return `${url}/getFarmsForSalesOrder?${qs.stringify(query)}`;
				}
				return url;
			},
		}),
	}),
});
export const {
	useLazyGetFarmsForSalesOrderQuery,
	useGetFarmsQuery,
	useLazyGetFarmsQuery,
	useGetFarmsReportQuery,
	useCreateFarmsMutation,
	useUpdateFarmsMutation,
	useUpdateRecycleFarmsMutation,
	useDeleteFarmsMutation,
} = categoriesApi;
