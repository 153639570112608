import { DownloadOutlined } from "@ant-design/icons";
import { Badge, Button, Col, Form, Input, notification, Popconfirm, Row, Space, Table } from "antd";
import { useGetDetailSellVoiTransQuery, useLazyGetDetailSellVoiTransQuery, useSellRecallMutation, useSellTransMutation } from "api";
import { OBJ_COLOR } from "commons/constants";
import { CardComponent } from "components";
import dayjs from "dayjs";
import { useExportExcel } from "hooks/useExportExcel";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";

interface Pagination {
	current: number;
	pageSize: number;
	total: number;
	showSizeChanger: boolean;
}

export const SellTransation = () => {
	const [form] = Form.useForm();
	const [param, setParam] = useState({ pageNumber: 1, pageSize: 20 });
	const [search, setSearch] = useState({});
	const [sellRecall] = useSellRecallMutation();
	const [sellTrans] = useSellTransMutation();
	const [pagination, setPagination] = useState<Pagination>();
	const [getDetailSellVoiTrans] = useLazyGetDetailSellVoiTransQuery();

	const paramApi = useMemo(() => {
		const query = {
			...param,
		};
		if (!Object.keys(search).length) return query;

		return {
			...query,
			...search,
		};
	}, [param, search]);
	const { data: DATA, isLoading, isFetching } = useGetDetailSellVoiTransQuery(paramApi);

	const formatTime = (time: any) => {
		return moment(time).format("YYYY-MM-DD HH:mm");
	};
	useEffect(() => {
		if (!DATA) return;

		setPagination({
			current: param.pageNumber,
			pageSize: param.pageSize,
			total: DATA.totalCount,
			showSizeChanger: true,
		});
	}, [DATA, param]);

	const handleOnChangePagination = (value: any) => {
		setParam({ pageNumber: value.current, pageSize: value.pageSize });
	};
	const onFinish = (value: any) => {
		const paramSearch = Object.fromEntries(Object.entries(value).filter(([key, index]) => index));
		setSearch({ ...paramSearch });
		setParam((prev) => ({ ...prev, pageNumber: 1 }));
	};

	const handleReset = () => {
		setSearch({});
		setParam((prev) => ({ ...prev, pageNumber: 1 }));
		form.resetFields();
	};
	const handleRecall = async (value: number) => {
		try {
			await sellRecall({ id: value }).unwrap();

			notification.success({
				message: "Success",
			});

		} catch (e: any) {
			notification.error({
				message: e.data.message,
			});
		}
	};
	const handlevoidTrans = async (value: number) => {
		try {
			const dataVoid: any = await sellTrans({ id: value });

			if (dataVoid.data) {
				notification.success({
					message: "Success",
				});
			} else {
				notification.error({
					message: "Error",
				});
			}
		} catch (e: any) {
			notification.error({
				message: e.data.message,
			});
		}
	};
	const [isLoadingButton, setIsLoadingButton] = useState(false);
	const { message, exportExcel } = useExportExcel();

	const handleOnClickExport = async () => {
		setIsLoadingButton(true);
		try {
			const dataExport = await getDetailSellVoiTrans({ ...param, ...search, pageSize: 10000, pageNumber: 1 }).unwrap();

			const Heading: any = [
				{ stt: "Stt", farm_code: "Farm Code", farm_name: "Farm Name", doc_date: "Date", source: "Source", user: "User", created_date: "System date", trans_id: "TRANS ID", farm_id: "Farm ID" },
			];
			const wscols: any = [{ wch: 8 }, { wch: 10 }, { wch: 25 }, { wch: 13 }, { wch: 10 }, { wch: 15 }, { wch: 15 }, { wch: 10 }, { wch: 20 }];
			const header: any[] = [];
			const data = dataExport.items.map((item, key) => {
				return {
					stt: key + 1,
					bu: item.bu_code,
					farm_code: item.farm_code,
					farm_name: item.farm_name,
					doc_date: item.doc_date,
					source: item.source,
					user: item.username,
					created_date: dayjs(item.created_date).format("YYYY-MM-DD"),
					trans_id: item.id,
					farm_id: item.farm_id,
				};
			});
			exportExcel({ Heading, wscols, header, dataExport: data, nameFile: "void-transation" });
		} catch (error) { }
	};

	useEffect(() => {
		if (message !== "Success") return;

		setIsLoadingButton(false);
	}, [message]);

	return (
		<CardComponent
			title={"Voi Transation"}
			extra={
				<Button loading={isLoadingButton} type="primary" onClick={handleOnClickExport} icon={<DownloadOutlined />}>
					Export
				</Button>
			}>
			<Form name="basic" onFinish={onFinish} form={form} style={{ width: "100%" }} layout="vertical">
				<Row gutter={[16, 0]} align="bottom">
					<Col xs={12} xl={5}>
						<Form.Item label="TransID From" name="trans_id_from">
							<Input placeholder="TransID From" />
						</Form.Item>
					</Col>
					<Col xs={12} xl={5}>
						<Form.Item label="TransID To" name="trans_id_to">
							<Input placeholder="TransID To" />
						</Form.Item>
					</Col>
					<Col xs={12} xl={5}>
						<Form.Item label="Farm Code" name="farm_code">
							<Input placeholder="Farm Code" />
						</Form.Item>
					</Col>
					<Col xs={12} xl={5}>
						<Form.Item>
							<Button style={{ width: "100%" }} type="primary" htmlType="submit">
								Search
							</Button>
						</Form.Item>
					</Col>
					<Col xs={12} xl={4}>
						<Form.Item>
							<Button style={{ width: "100%", background: OBJ_COLOR.WARNING }} type="primary" onClick={handleReset}>
								Reset
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			<Table
				onChange={handleOnChangePagination}
				pagination={pagination}
				dataSource={DATA?.items?.map((item, key) => ({ ...item, key: key }))}
				loading={isLoading || isFetching}
				scroll={{ x: true }}
				style={{ whiteSpace: "nowrap" }}>
				<Table.Column<SellTransTable>
					title="BU"
					render={(_, record) => {
						return record.bu_code;
					}}
				/>
				<Table.Column<SellTransTable>
					title="Farm Code"
					render={(_, record) => {
						return record.farm_code;
					}}
				/>

				<Table.Column<SellTransTable>
					title="Farm Name"
					render={(_, record) => {
						return record.farm_name;
					}}
				/>
				<Table.Column<SellTransTable>
					title="Date"
					render={(_, record) => {
						return record.doc_date;
					}}
				/>
				<Table.Column<SellTransTable>
					title="Source"
					render={(_, record) => {
						return <Badge color={OBJ_COLOR.WARNING} count={record.source} />;
					}}
				/>
				<Table.Column<SellTransTable>
					title="User"
					render={(_, record) => {
						return record.username;
					}}
				/>
				<Table.Column<SellTransTable>
					title="System date"
					render={(_, record) => {
						return formatTime(record.created_date);
					}}
				/>
				<Table.Column<SellTransTable>
					title="TRANS ID"
					render={(_, record) => {
						return record.id;
					}}
				/>
				<Table.Column<SellTransTable>
					title="Farm ID"
					render={(_, record) => {
						return record.farm_id;
					}}
				/>
				<Table.Column<Voidtrans>
					title=""
					render={(_, record) => {
						return (
							<Space>
								<Popconfirm placement="topRight" title={"Void Trans"} description={"Are you sure to Sell Trans?"} onConfirm={() => handlevoidTrans(record.id)} okText="Yes" cancelText="No">
									<Button style={{ background: OBJ_COLOR.DANGER }} type="primary">
										Void Trans
									</Button>
								</Popconfirm>
								<Popconfirm placement="topRight" title={"Recall"} description={"Are you sure to Recall?"} onConfirm={() => handleRecall(record.id)} okText="Yes" cancelText="No">
									<Button type="primary">Recall</Button>
								</Popconfirm>
							</Space>
						);
					}}
				/>
			</Table>
		</CardComponent>
	);
};
