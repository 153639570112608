import { useState } from "react";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

interface useExportExcelProps {
	Heading: any;
	wscols: any;
	header: any;
	dataExport: any;
	nameFile: string;
}

export const useExportExcel = () => {
	const [message, setMessage] = useState<string>("");

	const exportExcel = ({ Heading, wscols, header, dataExport, nameFile }: useExportExcelProps) => {
		const ws = XLSX.utils.json_to_sheet(Heading, {
			header,
			skipHeader: true,
		});
		ws["!cols"] = wscols;

		XLSX.utils.sheet_add_json(ws, dataExport || [], {
			header,
			skipHeader: true,
			origin: -1, //ok
		});
		const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, nameFile + fileExtension);
		setMessage("Success");
	};

	return { message, exportExcel };
};
