import { api } from ".";

const authApi = api.injectEndpoints({
	endpoints: (build) => ({
		login: build.mutation<UserAuth, { userId: string; password: string }>({
			query: (body) => ({
				url: "/auth/login",
				method: "POST",
				body,
			}),
		}),
	}),
});
export const { useLoginMutation } = authApi;
