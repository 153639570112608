import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Space, Typography, notification } from "antd";
import { useCreateReportMutation, useGetCategoriesBreedInByFarmQuery, useGetCategoriesQuery } from "api";
import { OBJ_COLOR } from "commons/constants";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useAppSelector } from "store";
import styled from "styled-components";
// import "dayjs/locale/zh-cn";

const { Text } = Typography;
interface ReportModalProps {
	visible: boolean;
	setVisible: (visible: boolean) => void;
	type?: string;
	dataModal: FarmsReport | undefined;
}
const TextCustoms = styled(Text)`
	display: inline-block;
	margin-bottom: 5px;
	margin-left: 10px;
`;

export const ReportModal = ({ visible, setVisible, type, dataModal }: ReportModalProps) => {
	const [form] = Form.useForm();
	const [isInput, setIsInput] = useState(false);
	const user = useAppSelector((state) => state.user);
	const [createReport, { isLoading }] = useCreateReportMutation();
	const { data: BreedList } = useGetCategoriesQuery({ datatype_id: 1, active: true, pageSize: 1000 });
	const { data: FeedList } = useGetCategoriesQuery({ datatype_id: 2, active: true, pageSize: 1000 });
	const { data: MedicineList } = useGetCategoriesQuery({ datatype_id: 3, active: true, pageSize: 1000 });
	const { data: ChickenLossList } = useGetCategoriesQuery({ datatype_id: 4, active: true, pageSize: 1000 });
	const { data: dataBreedInByFarm } = useGetCategoriesBreedInByFarmQuery({ farmCode: dataModal?.farm_code }, { skip: !dataModal });

	useEffect(() => {
		if (!visible) return;

		form.resetFields();
	}, [visible, form]);

	const handleOk = () => {
		form.submit();
	};

	const onFinish = async (value: any) => {
		const { date, ...rest } = value;

		const aaa = Object.fromEntries(Object.entries(rest).filter(([keys, index]) => (index as any[])[0].code));
		const data = Object.entries(aaa).map((item: any[]) => {
			const aaa = item[1].map((value: any[]) => ({ ...value, datatype_name: item[0] }));
			return aaa;
		});
		if (data.length < 1) return setIsInput(true);

		const newData = {
			doc_date: dayjs(date as string).format("YYYY-MM-DD"),
			farm_code: dataModal?.farm_code,
			user_id: user?.id,
			source: type,
			data: data.flat(),
		};

		try {
			await createReport(newData).unwrap();
			setVisible(false);
			notification.success({
				message: "Success",
			});
		} catch (e: any) {
			notification.error({
				message: e.data.message,
			});
		}
	};

	const handleOnChange = (value: any) => {
		if (!value) return;

		setIsInput(false);
	};

	return (
		<Modal
			maskClosable={false}
			width={1000}
			title={
				<Space direction="vertical" size={0} style={{ whiteSpace: "break-spaces" }}>
					<Text style={{ fontWeight: 700, fontSize: 20, color: OBJ_COLOR.SUCCESS }}>BÁO CÁO {type?.includes("IN") ? "NHẬP" : type === "CUT-OFF" ? "TRẢ LẠI" : "XUẤT"} </Text>
					<Text style={{ fontWeight: "normal", fontSize: 16 }}>Tên trại: {`${dataModal?.farm_code} - ${dataModal?.farm_name}`}</Text>
				</Space>
			}
			bodyStyle={{ padding: 0 }}
			open={visible}
			onOk={handleOk}
			okText="Submit"
			onCancel={() => setVisible(false)}
			confirmLoading={isLoading}>
			<Form form={form} name="basic" layout="vertical" onFinish={onFinish} initialValues={{ feed: [""], medicine: [""], breed: [""], other: [""], chickenloss: [""] }} autoComplete="off">
				<Row gutter={16}>
					<Col span={24}>
						<Text>Người báo cáo: {user?.fullname}</Text>
						<Space style={{ width: "100%" }}>
							<Text>Ngày báo cáo:</Text>
							<Form.Item style={{ margin: 0, width: 200 }} name="date" rules={[{ required: true, message: "Vui lòng chọn ngày !" }]}>
								<DatePicker disabledDate={(date) => date.isAfter(dayjs())} style={{ borderWidth: 0, borderBottomWidth: 1, borderRadius: 0, width: "100%", boxShadow: "none" }} />
							</Form.Item>
						</Space>
					</Col>
					<Col span={12}></Col>
				</Row>
				{type?.includes("IN") && (
					<Form.List name="breed">
						{(fields, { add, remove }) => (
							<>
								<Space align="center" style={{ width: "100%", justifyContent: "space-between", display: "flex" }}>
									<TextCustoms strong style={{ color: OBJ_COLOR.DANGER }}>
										CON GIỐNG
									</TextCustoms>
									<Form.Item style={{ margin: 0 }}>
										<Button style={{ padding: "0 10px", borderColor: OBJ_COLOR.DANGER, color: OBJ_COLOR.DANGER }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}></Button>
									</Form.Item>
								</Space>

								{fields.map(({ key, name, ...restField }) => (
									<Row key={key} gutter={16}>
										<Col span={15}>
											<Form.Item {...restField} name={[name, "code"]}>
												<Select
													showSearch
													onChange={handleOnChange}
													style={{ width: "100%" }}
													options={BreedList?.items.map((item, key) => ({ key: key, label: `${item?.code} ${item?.name ? `- ${item?.name} - DVT:${item.uom}` : ""} `, value: item.code }))}
													allowClear
													placeholder="--- Chọn con giống ---"
												/>
											</Form.Item>
										</Col>
										<Col span={8}>
											<Form.Item {...restField} name={[name, "quantity"]}>
												<InputNumber onChange={handleOnChange} style={{ width: "100%" }} placeholder="Số lượng" />
											</Form.Item>
										</Col>
										<Col span={1}>{fields.length > 1 ? <MinusCircleOutlined style={{ color: OBJ_COLOR.DANGER }} onClick={() => remove(name)} /> : null}</Col>
									</Row>
								))}
							</>
						)}
					</Form.List>
				)}
				<Form.List name="feed">
					{(fields, { add, remove }) => (
						<>
							<Space align="center" style={{ width: "100%", justifyContent: "space-between", display: "flex" }}>
								<TextCustoms strong style={{ color: OBJ_COLOR.WARNING }}>
									CÁM
								</TextCustoms>
								<Form.Item style={{ margin: 0 }}>
									<Button style={{ padding: "0 10px", borderColor: OBJ_COLOR.WARNING, color: OBJ_COLOR.WARNING }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}></Button>
								</Form.Item>
							</Space>
							{fields.map(({ key, name, ...restField }) => (
								<Row key={key} gutter={16}>
									<Col span={15}>
										<Form.Item {...restField} name={[name, "code"]}>
											<Select
												showSearch
												style={{ width: "100%" }}
												options={FeedList?.items.map((item, key) => ({ key: key, label: `${item?.code} ${item?.name ? `- ${item?.name} - DVT:${item.uom}` : ""} `, value: item.code }))}
												allowClear
												onChange={handleOnChange}
												placeholder="--- Chọn cám ---"
											/>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item {...restField} name={[name, "quantity"]}>
											<InputNumber onChange={handleOnChange} style={{ width: "100%" }} placeholder="Số lượng" />
										</Form.Item>
									</Col>
									<Col span={1}>{fields.length > 1 ? <MinusCircleOutlined style={{ color: OBJ_COLOR.DANGER }} onClick={() => remove(name)} /> : null}</Col>
								</Row>
							))}
						</>
					)}
				</Form.List>
				<Form.List name="medicine">
					{(fields, { add, remove }) => (
						<>
							<Space align="center" style={{ width: "100%", justifyContent: "space-between", display: "flex" }}>
								<TextCustoms strong style={{ color: OBJ_COLOR.INFO }}>
									THUỐC
								</TextCustoms>
								<Form.Item style={{ margin: 0 }}>
									<Button style={{ padding: "0 10px", borderColor: OBJ_COLOR.INFO, color: OBJ_COLOR.INFO }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}></Button>
								</Form.Item>
							</Space>

							{fields.map(({ key, name, ...restField }) => (
								<Row key={key} gutter={16}>
									<Col span={15}>
										<Form.Item {...restField} name={[name, "code"]}>
											<Select
												showSearch
												style={{ width: "100%" }}
												options={MedicineList?.items.map((item, key) => ({ key: key, label: `${item?.code} ${item?.name ? `- ${item?.name} - DVT:${item.uom}` : ""} `, value: item.code }))}
												allowClear
												onChange={handleOnChange}
												placeholder="--- Chọn thuốc ---"
											/>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item {...restField} name={[name, "quantity"]}>
											<InputNumber onChange={handleOnChange} style={{ width: "100%" }} placeholder="Số lượng" />
										</Form.Item>
									</Col>
									<Col span={1}>{fields.length > 1 ? <MinusCircleOutlined style={{ color: OBJ_COLOR.DANGER }} onClick={() => remove(name)} /> : null}</Col>
								</Row>
							))}
						</>
					)}
				</Form.List>
				{!type?.includes("IN") && (
					<Form.List name="chickenloss">
						{(fields, { add, remove }) => (
							<>
								<Space align="center" style={{ width: "100%", justifyContent: "space-between", display: "flex" }}>
									<TextCustoms strong style={{ color: OBJ_COLOR.DANGER }}>
										HAO HỤT
									</TextCustoms>
									<Form.Item style={{ margin: 0 }}>
										<Button style={{ padding: "0 10px", borderColor: OBJ_COLOR.DANGER, color: OBJ_COLOR.DANGER }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}></Button>
									</Form.Item>
								</Space>

								{fields.map(({ key, name, ...restField }) => (
									<Row key={key} gutter={16}>
										<Col span={8}>
											<Form.Item {...restField} name={[name, "loss_code"]}>
												<Select
													showSearch
													onChange={handleOnChange}
													style={{ width: "100%" }}
													options={dataBreedInByFarm?.map((item, key) => ({ key: key, label: `${item?.code} ${item?.name ? `- ${item?.name} - DVT:${item.uom}` : ""} `, value: item.code }))}
													allowClear
													placeholder="--- Chọn con giống ---"
												/>
											</Form.Item>
										</Col>
										<Col span={7}>
											<Form.Item {...restField} name={[name, "code"]}>
												<Select
													showSearch
													onChange={handleOnChange}
													style={{ width: "100%" }}
													options={ChickenLossList?.items.map((item, key) => ({ key: key, label: `${item?.code} ${item?.name ? `- ${item?.name} - DVT:${item.uom}` : ""} `, value: item.code }))}
													allowClear
													placeholder="--- Chọn lý do ---"
												/>
											</Form.Item>
										</Col>
										<Col span={8}>
											<Form.Item {...restField} name={[name, "quantity"]}>
												<InputNumber onChange={handleOnChange} style={{ width: "100%" }} placeholder="Số lượng" />
											</Form.Item>
										</Col>
										<Col span={1}>{fields.length > 1 ? <MinusCircleOutlined style={{ color: OBJ_COLOR.DANGER }} onClick={() => remove(name)} /> : null}</Col>
									</Row>
								))}
							</>
						)}
					</Form.List>
				)}
				<Form.List name="other">
					{(fields, { add, remove }) => (
						<>
							<Space align="center" style={{ width: "100%", justifyContent: "space-between", display: "flex" }}>
								<TextCustoms strong style={{ color: OBJ_COLOR.SUCCESS }}>
									CÁC THỨ KHÁC
								</TextCustoms>
								<Form.Item style={{ margin: 0 }}>
									<Button style={{ padding: "0 10px", borderColor: OBJ_COLOR.SUCCESS, color: OBJ_COLOR.SUCCESS }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}></Button>
								</Form.Item>
							</Space>
							{fields.map(({ key, name, ...restField }) => (
								<Row key={key} gutter={16}>
									<Col span={15}>
										<Form.Item {...restField} name={[name, "code"]}>
											<Input onChange={handleOnChange} placeholder="Nhập tên" />
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item {...restField} name={[name, "quantity"]}>
											<InputNumber onChange={handleOnChange} style={{ width: "100%" }} placeholder="Số lượng" />
										</Form.Item>
									</Col>
									<Col span={1}>{fields.length > 1 ? <MinusCircleOutlined style={{ color: OBJ_COLOR.DANGER }} onClick={() => remove(name)} /> : null}</Col>
								</Row>
							))}
						</>
					)}
				</Form.List>
				<span style={{ color: OBJ_COLOR.DANGER }}>{isInput && "Vui lòng chọn hơn 1 "}</span>
			</Form>
		</Modal>
	);
};
