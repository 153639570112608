import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Upload, notification } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useUploadFileToServerMutation } from 'api/uploadFileApi';
import { useState } from 'react';

interface AddImageProps {
    setPathImage: (path: string) => void;
}


const AddImage = ({ setPathImage }: AddImageProps) => {

    const [uploadFileToServer] = useUploadFileToServerMutation();

    const [loading, setLoading] = useState(false);


    const handleBeforeUpload = async (file: RcFile) => {
        setLoading(true);
        try {

            const fmData = new FormData();
            fmData.append("file", file);
            const { path } = await uploadFileToServer(fmData).unwrap();
            setPathImage(path);

        } catch (error: any) {
            notification.error({ message: error?.data?.message });
        }

        setLoading(false);

        return false;
    }

    return (
        <Upload
            listType="picture-card"
            showUploadList={false}
            maxCount={1}
            beforeUpload={handleBeforeUpload}
            disabled={loading}
        >
            <button style={{ border: 0, background: 'none' }} type="button">
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
            </button>
        </Upload>
    )
}

export default AddImage;