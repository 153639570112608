import { useCallback, useState } from "react";

const useFormToggle = () => {
	const [open, setOpen] = useState<boolean>(false);

	const onOpen = useCallback(() => {
		setOpen(true);
	}, []);

	const onClose = useCallback(() => {
		setOpen(false);
	}, []);

	return { open, onOpen, onClose };
};

export default useFormToggle;
