import { api } from "api/baseApi";
import qs from "query-string";

const url = "/customer";

export const { useLazyGetCustomersQuery, useLazyGetCustomerShippingQuery } = api.injectEndpoints({
	endpoints: (build) => ({
		getCustomers: build.query<Customer[], Record<string, any>>({
			query: (query) => `${url}?${qs.stringify(query)}`,
		}),

		getCustomerShipping: build.query<CustomerShipping[], string>({
			query: (customerId) => `${url}/shipping/${customerId}`,
		}),
	}),
});
