import { CheckOutlined, CloseOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Table, Space, Button } from "antd";
import { OBJ_COLOR } from "commons/constants";

interface TableCategoriesProps {
	handleOnChangePagination: (value: any) => void;
	handleOnClickDelete: (value: DataCategoriesType) => void;
	handleOnClickEdit: (value: any) => void;
	dataSource: DataCategoriesType[];
	loading?: boolean;
	pagination: { current: number; pageSize: number; total: number; showSizeChanger: boolean } | undefined;
}
export const TableCategories = ({ dataSource, loading, handleOnChangePagination, handleOnClickEdit, handleOnClickDelete, pagination }: TableCategoriesProps) => {
	return (
		<Table
			onChange={handleOnChangePagination}
			pagination={pagination}
			dataSource={dataSource.map((item, key) => ({ ...item, key: key + 1 }))}
			loading={loading}
			scroll={{ x: true }}
			style={{ whiteSpace: "nowrap" }}>
			<Table.Column title="STT" dataIndex="key" align="center" width={50} />
			<Table.Column<DataCategoriesType>
				title="Code"
				render={(_, record) => {
					return record.code;
				}}
			/>
			<Table.Column<DataCategoriesType>
				title="Name"
				render={(_, record) => {
					return record.name;
				}}
			/>
			<Table.Column<DataCategoriesType>
				title="UOM"
				align="center"
				render={(_, record) => {
					return record.uom;
				}}
			/>
			<Table.Column<DataCategoriesType>
				title="Active"
				align="center"
				render={(_, record) => {
					return record.active ? <CheckOutlined style={{ color: OBJ_COLOR.SUCCESS }} /> : <CloseOutlined style={{ color: OBJ_COLOR.DANGER }} />;
				}}
			/>
			<Table.Column<DataCategoriesType>
				align="center"
				width={270}
				render={(_, record) => {
					return (
						<Space>
							<Button onClick={() => handleOnClickEdit(record)} style={{ background: OBJ_COLOR.SUCCESS }} type="primary" icon={<EditOutlined />}>
								Edit
							</Button>
							<Button onClick={() => handleOnClickDelete(record)} style={{ background: OBJ_COLOR.DANGER }} type="primary" icon={<DeleteOutlined />}>
								Delete
							</Button>
						</Space>
					);
				}}
			/>
		</Table>
	);
};
