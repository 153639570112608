import { Card } from "antd";

interface CardComponentProps {
	extra?: any;
	children: any;
	title: any;
}
export const CardComponent = ({ extra, children, title }: CardComponentProps) => {
	return (
		<Card title={title} bordered={false} style={{ width: "100%", borderTop: "3px solid #45b148" }} extra={extra}>
			{children}
		</Card>
	);
};
