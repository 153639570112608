import { combineReducers, configureStore, isRejectedWithValue, Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { notification } from "antd";
import { api } from "api";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import { removeUser, userReducer } from "slice";

const persistConfig = {
	key: "root",
	storage,
	stateReconciler: autoMergeLevel2,
	whitelist: ["user"],
	blacklist: [api.reducerPath],
	transforms: [
		encryptTransform({
			secretKey: "secretKey",
			onError: function (error) {
				notification.error({ message: error.message });
			},
		}),
	],
};

const rootReducer = combineReducers({
	user: userReducer,

	[api.reducerPath]: api.reducer,
});

const persistedReducer = persistReducer<ReturnType<typeof rootReducer>>(persistConfig, rootReducer);

const errorHandleMiddleware: Middleware = (api: MiddlewareAPI) => (next: any) => (action: any) => {
	if (isRejectedWithValue(action) && action?.payload?.status === 400) {
		console.log(action.payload, "400");
	} else if (isRejectedWithValue(action) && action?.payload?.status === 401) {
		api.dispatch(removeUser());
		window.location.href = "/auth/login";
		return;
	} else if (isRejectedWithValue(action) && action?.payload?.status === 403) {
		console.log(action.payload, "403");
		notification.error({
			message: action.payload.data.message[0],
		});
	} else if (isRejectedWithValue(action) && action?.payload?.status === 404) {
		console.log(action.payload, "404");
		notification.error({
			message: action.payload.data.message[0],
		});
	} else if (isRejectedWithValue(action)) {
		console.log(action.payload, "");
	}
	return next(action);
};

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(api.middleware, errorHandleMiddleware),
	devTools: true,
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type RootState = ReturnType<typeof rootReducer>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const persistor = persistStore(store);
export default store;
