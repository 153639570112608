import { api, providesList } from ".";
import qs from "query-string";

const url = "/user-farm";

const userFarmApi = api.injectEndpoints({
	endpoints: (build) => ({
		getUserFarm: build.query<UserFarm[], Record<string, any> | undefined>({
			query: (query) => {
				if (query) {
					return `${url}?${qs.stringify(query)}`;
				}
				return url;
			},
			providesTags: (result) => providesList(result, "user-farm"),
		}),
		createUserFarm: build.mutation<void, Record<string, any>>({
			query: (data) => {
				return {
					url,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: (result, error, { id }) => {
				return error ? [] : [{ type: "user-farm", id }];
			},
		}),
		deleteUserFarm: build.mutation<void, number | undefined>({
			query: (id) => {
				return {
					url: `${url}/${id}`,
					method: "DELETE",
				};
			},
			// invalidatesTags: (result, error, id) => {
			// 	return error ? [] : [{ type: "user-farm", id }];
			// },
		}),
	}),
});
export const { useGetUserFarmQuery, useLazyGetUserFarmQuery, useCreateUserFarmMutation, useDeleteUserFarmMutation } = userFarmApi;
