import { CloseOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined, MoreOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Dropdown, Flex, Form, Grid, Input, MenuProps, Modal, Result, Row, Table, TableColumnsType, Tag, Typography, notification } from "antd";
import { useCancelSalesOrderMutation, useDeleteSalesOrderMutation, useGetSalesOrdersQuery } from "api/sales-order/sales-order-api";
import { OBJ_COLOR } from "commons/constants";
import { StatusSalesOrder, getColorStatusSalesOrder } from "commons/constants/sales-order";
import { dateFormat } from "commons/constants/util";
import { CardComponent } from "components";
import dayjs from "dayjs";
import useFormToggle from "hooks/useFormToggle";
import usePaginated from "hooks/usePages";
import { useState } from "react";
import ReportScaleDrawer from "./components/ReportScaleDrawer";
import SalesOrderModal from "./components/SalesOrderModal";
import SeeSalesOrderDrawer from "./components/SeeSalesOrderDrawer";
import { useAppSelector } from "store";

const { useBreakpoint } = Grid;

const SalesOrder = () => {
	const user = useAppSelector(state => state.user);

	const screens = useBreakpoint();

	const { open: openSalesOrderModal, onOpen: onOpenSalesOrderModal, onClose: onCloseSalesOrderModal } = useFormToggle();
	const { open: openSeeSalesOrderDrawer, onOpen: onOpenSeeSalesOrderDrawer, onClose: onCloseSeeSalesOrderDrawer } = useFormToggle();
	const { open: openReportScaleDrawer, onOpen: onOpenReportScaleDrawer, onClose: onCloseReportScaleDrawer } = useFormToggle();

	const [form] = Form.useForm();

	const { params, setParams, pageSize, setPageNumber, setPageSize } = usePaginated();

	const { data: dataSalesOrders, isFetching } = useGetSalesOrdersQuery(params);

	const [deleteSalesOrder] = useDeleteSalesOrderMutation();
	const [cancelSalesOrder] = useCancelSalesOrderMutation();

	const [salesOrderIdModal, setSalesOrderIdModal] = useState<number>(0);
	const [salesOrderIdDrawer, setSalesOrderIdDrawer] = useState<number>(0);

	const handleOnOpenSalesOrderModal = () => {
		onOpenSalesOrderModal();
	};

	const handleDeleteSalesOrder = (salesOrderId: number) => {
		Modal.confirm({
			title: "Xác nhận xoá đơn hàng",
			icon: <ExclamationCircleOutlined />,
			content: "Hành vi này không thể thu hồi lại đơn hàng.",
			okText: "Đồng ý",
			cancelText: "Huỷ",
			onOk: async () => {
				try {
					await deleteSalesOrder(salesOrderId).unwrap();
					notification.success({ message: "Xoá thành công." });
				} catch (error) { }
			},
		});
	};

	const handleCancelSalesOrder = (salesOrderId: number) => {
		Modal.confirm({
			title: "Xác nhận huỷ đơn hàng",
			icon: <ExclamationCircleOutlined />,
			content: "Hành vi này không thể thu hồi lại đơn hàng.",
			okText: "Đồng ý",
			cancelText: "Huỷ",
			onOk: async () => {
				try {
					await cancelSalesOrder(salesOrderId).unwrap();
					notification.success({ message: "Huỷ thành công." });
				} catch (error: any) {
					notification.error({ message: error?.data?.message });
				}
			},
		});
	};

	const renderItems = (salesOrderId: number, status: StatusSalesOrder) => {
		const items: MenuProps["items"] = [
			{
				key: "1",
				label: <Typography.Text style={{ color: OBJ_COLOR.SUCCESS }}>Xem chi tiết</Typography.Text>,
				icon: <EyeOutlined style={{ color: OBJ_COLOR.SUCCESS }} />,
				onClick: () => {
					setSalesOrderIdDrawer(salesOrderId);
					onOpenSeeSalesOrderDrawer();
				},
			},
		];

		if (status === "Draft") {
			items.push({
				key: "2",
				label: <Typography.Text style={{ color: OBJ_COLOR.INFO }}>Chỉnh sửa</Typography.Text>,
				icon: <EditOutlined style={{ color: OBJ_COLOR.INFO }} />,
				onClick: () => {
					setSalesOrderIdModal(salesOrderId);
					handleOnOpenSalesOrderModal();
				},
			});

			items.push({
				key: "3",
				label: <Typography.Text style={{ color: OBJ_COLOR.DANGER }}>Xoá</Typography.Text>,
				icon: <DeleteOutlined style={{ color: OBJ_COLOR.DANGER }} />,
				onClick: () => {
					handleDeleteSalesOrder(salesOrderId);
				},
			});
		}

		if (status === "Submit") {
			items.push({
				key: "99",
				label: <Typography.Text style={{ color: OBJ_COLOR.DANGER }}>Cancel</Typography.Text>,
				icon: <CloseOutlined style={{ color: OBJ_COLOR.DANGER }} />,
				onClick: () => {
					handleCancelSalesOrder(salesOrderId);
				},
			});
		}

		return items;
	};

	const columns: TableColumnsType<SalesOrder> = [
		{
			title: "STT",
			dataIndex: "key",
			ellipsis: true,
		},
		{
			title: "Mã SO",
			dataIndex: "id",
			render: (id: number) => (
				<Typography.Text type="success" strong>
					{id}
				</Typography.Text>
			),
			ellipsis: true,
		},
		{
			title: "Mã trại",
			dataIndex: "farmCode",
			render: (value) => <div style={{ whiteSpace: "nowrap" }}>{value}</div>,
			ellipsis: true,
		},
		{
			title: "Tên trại",
			dataIndex: "farmName",
			render: (value) => <div style={{ whiteSpace: "nowrap" }}>{value}</div>,
			ellipsis: true,
		},
		{
			title: "Mã KH",
			dataIndex: "customerId",
			render: (value) => <div style={{ whiteSpace: "nowrap" }}>{value}</div>,
			ellipsis: true,
		},
		{
			title: "Tên KH",
			dataIndex: "customerName",
			render: (value) => <div style={{ whiteSpace: "nowrap" }}>{value}</div>,
			ellipsis: true,
		},
		{
			title: "SĐT KH",
			dataIndex: "custPhone",
			render: (value) => <div style={{ whiteSpace: "nowrap" }}>{value}</div>,
			ellipsis: true,
		},
		{
			title: "Ngày tạo đơn hàng",
			dataIndex: "orderDate",
			render: (orderDate) => dateFormat(orderDate),
			ellipsis: true,
		},
		{
			title: "Ngày giao hàng",
			dataIndex: "shipDate",
			render: (orderDate) => dateFormat(orderDate),
			ellipsis: true,
		},
		{
			title: "Địa chỉ giao",
			dataIndex: "shipAddress",
			render: (value) => <div style={{ whiteSpace: "nowrap" }}>{value}</div>,
			ellipsis: true,
		},
		{
			title: "Tên tài xế",
			dataIndex: "driver",
			render: (value) => <div style={{ whiteSpace: "nowrap" }}>{value}</div>,
			ellipsis: true,
		},
		{
			title: "SĐT Tài xế",
			dataIndex: "driverPhone",
			render: (value) => <div style={{ whiteSpace: "nowrap" }}>{value}</div>,
			ellipsis: true,
		},
		{
			title: "Trạng thái",
			dataIndex: "status",
			fixed: "right",
			render: (status) => <Tag color={getColorStatusSalesOrder(status)}>{status}</Tag>,
			ellipsis: true,
		},
		{
			dataIndex: "id",
			fixed: "right",
			render: (id: number, record) => (
				<Dropdown menu={{ items: renderItems(id, record.status as StatusSalesOrder) }}>
					<MoreOutlined style={{ cursor: "pointer", fontSize: 18 }} />
				</Dropdown>
			),
			ellipsis: true,
		},
	];

	const handleOnFinish = (values: { salesOrderId?: number; farmCode?: string; customerId?: string; orderDate: dayjs.Dayjs[] }) => {
		const { salesOrderId, farmCode, customerId, orderDate } = values;
		setParams({ salesOrderId, farmCode, customerId, startDate: orderDate?.[0].toISOString(), endDate: orderDate?.[1].toISOString() });
	};

	const handleReset = () => {
		form.resetFields();
		setParams({});
	};

	if (user?.role === "Staff" || user?.role === "Sale") {
		if (!["luan.nt", "thong.dq", "truong.nd", "duong.nc"].includes(user.username)) {
			return <Result status="info" title="401" subTitle="Rất tiếc bạn không có quyền truy cập !" />
		}
	}

	return (
		<>
			<CardComponent
				title={
					<Flex justify="space-between" align="center">
						<Typography.Title level={4} style={{ marginBottom: 0 }}>
							Sales Order
						</Typography.Title>
						<Flex gap={10}>
							<Button
								ghost
								type="primary"
								icon={<PlusCircleOutlined />}
								onClick={() => {
									handleOnOpenSalesOrderModal();
									setSalesOrderIdModal(0);
								}}>
								Tạo mới
							</Button>
							{!screens.xs && (
								<Button type="primary" icon={<EyeOutlined />} onClick={onOpenReportScaleDrawer}>
									Xem báo cáo
								</Button>
							)}
						</Flex>
					</Flex>
				}>
				<Row>
					<Col span={24}>
						<Form form={form} layout="vertical" onFinish={handleOnFinish}>
							<Row gutter={[10, 10]} align="bottom">
								<Col xs={12} xl={4}>
									<Form.Item label="Sales Order Id" name="salesOrderId">
										<Input placeholder="Sales Order Id" />
									</Form.Item>
								</Col>
								<Col xs={12} xl={4}>
									<Form.Item label="Customer Id" name="customerId">
										<Input placeholder="Customer Id" />
									</Form.Item>
								</Col>
								<Col xs={12} xl={4}>
									<Form.Item label="Order Date" name="orderDate">
										<DatePicker.RangePicker format={"DD-MM-YYYY"} style={{ width: "100%" }} />
									</Form.Item>
								</Col>
								<Col xs={12} xl={4}>
									<Form.Item>
										<Button style={{ width: "100%" }} type="primary" htmlType="submit">
											Search
										</Button>
									</Form.Item>
								</Col>
								<Col xs={12} xl={4}>
									<Form.Item>
										<Button onClick={handleReset} style={{ width: "100%", background: OBJ_COLOR.WARNING }} type="primary">
											Reset
										</Button>
									</Form.Item>
								</Col>
							</Row>
						</Form>
					</Col>
					<Col span={24}>
						<Table
							loading={isFetching}
							columns={columns}
							dataSource={dataSalesOrders?.items.map((item, key) => ({ ...item, key: key + 1 }))}
							scroll={{ x: true }}
							pagination={{
								total: dataSalesOrders?.totalCount,
								pageSize,
								onChange: (pageNumber: number, pageSize: number) => {
									setPageNumber(pageNumber);
									setPageSize(pageSize);
								},
							}}
						/>
					</Col>
				</Row>
			</CardComponent>
			<SalesOrderModal salesOrderId={salesOrderIdModal} open={openSalesOrderModal} onClose={onCloseSalesOrderModal} />
			<SeeSalesOrderDrawer salesOrderId={salesOrderIdDrawer} open={openSeeSalesOrderDrawer} onClose={onCloseSeeSalesOrderDrawer} />
			<ReportScaleDrawer open={openReportScaleDrawer} onClose={onCloseReportScaleDrawer} />
		</>
	);
};

export default SalesOrder;
