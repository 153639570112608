import { Result } from "antd";
import "antd/dist/reset.css";
import { PATHS } from "commons/constants";
import { Layout } from "layout";
import { CreateReport, FarmReporting, User, VoidTransation } from "pages";
import { ChangePassword, Login } from "pages/authentication";
import { BreedList, ChickenLossList, FarmList, FeedList, MedicineList } from "pages/categories";
import PigScale from "pages/pig-scale";
import SalesOrder from "pages/sales-order";
import ScaleHistory from "pages/scale";
import { SellReporting } from "pages/sellReporting";
import { SellTransation } from "pages/sellTransation";
import { useMemo } from "react";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useAppSelector } from "store";

interface ProtectedRouteProps {
	children: JSX.Element;
}
const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
	const location = useLocation();
	const user = useAppSelector((state) => state.user);

	const newPath = useMemo(() => {
		if (user?.role === "Staff") return PATHS.filter((path) => path.role === "staff" || path.role === "sale");
		else if (user?.role === "Sale") return PATHS.filter((path) => path.role === "sale");
		else return PATHS;
	}, [user]);

	if (!user || !newPath.length) {
		return <Navigate to="/auth/login" state={{ from: location }} replace />;
	}

	if (newPath.some((item) => item.path === location.pathname)) {
		return children;
	}

	return <Navigate to={newPath[0].path} state={{ from: location }} replace />;
};

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route element={<Layout />}>
					<Route path="/" element={<Navigate to={"/create-report"} replace />} />
					<Route path="/auth/change-password" element={<ChangePassword />}></Route>
					<Route
						path="/create-report"
						element={
							<ProtectedRoute>
								<CreateReport />
							</ProtectedRoute>
						}></Route>
					<Route
						path="/user-list"
						element={
							<ProtectedRoute>
								<User />
							</ProtectedRoute>
						}></Route>
					<Route
						path="/sell-report"
						element={
							<ProtectedRoute>
								<SellReporting />
							</ProtectedRoute>
						}></Route>
					<Route
						path="/farm-report"
						element={
							<ProtectedRoute>
								<FarmReporting />
							</ProtectedRoute>
						}></Route>
					<Route
						path="/voi-transation"
						element={
							<ProtectedRoute>
								<VoidTransation />
							</ProtectedRoute>
						}></Route>
					<Route
						path="/sell-transation"
						element={
							<ProtectedRoute>
								<SellTransation />
							</ProtectedRoute>
						}></Route>
					<Route
						path="/pig-scale"
						element={
							<ProtectedRoute>
								<PigScale />
							</ProtectedRoute>
						}></Route>
					<Route
						path="/sales-order"
						element={
							<ProtectedRoute>
								<SalesOrder />
							</ProtectedRoute>
						}></Route>
					<Route path="/categories/*">
						<Route path="breed" element={<BreedList />} />
						<Route path="farm" element={<FarmList />} />
						<Route path="feed" element={<FeedList />} />
						<Route path="medicine" element={<MedicineList />} />
						<Route path="chicken-loss" element={<ChickenLossList />} />
						<Route path="" element={<Navigate to={"breed"} replace />} />
					</Route>
				</Route>
				<Route path="/scale" element={<ScaleHistory />}></Route>
				<Route path="/auth/login" element={<Login />}></Route>
				<Route path="*" element={<Result status="500" title="500" subTitle="Sorry, something went wrong." />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
