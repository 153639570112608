import qs from "query-string";
import { api, providesList } from ".";

const url = "/categories";

const categoriesApi = api.injectEndpoints({
	endpoints: (build) => ({
		getCategories: build.query<Categories, Record<string, any> | undefined>({
			query: (query) => {
				if (query) {
					return `${url}?${qs.stringify(query)}`;
				}
				return url;
			},
			providesTags: (result) => providesList(result?.items, "categories"),
		}),
		getCategoriesSell: build.query<CategoriesSell[], Record<string, any> | undefined>({
			query: (query) => {
				if (query) {
					return `${url}/sell-breed?${qs.stringify(query)}`;
				}
				return url;
			},
			providesTags: (result) => providesList(result, "categories-sell"),
		}),
		createCategories: build.mutation<void, Record<string, any>>({
			query: (data) => {
				return {
					url,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: (result, error, { id }) => {
				return error ? [] : [{ type: "categories", id }];
			},
		}),
		updateCategories: build.mutation<void, Record<string, any>>({
			query: (body) => {
				const { id } = body;
				return {
					url: `${url}/edit/${id}`,
					method: "PUT",
					body,
				};
			},
			invalidatesTags: (result, error, { id }) => {
				return error ? [] : [{ type: "categories", id }];
			},
		}),
		deleteCategories: build.mutation<void, number | undefined>({
			query: (id) => {
				return {
					url: `${url}/${id}`,
					method: "DELETE",
				};
			},
			invalidatesTags: (result, error) => {
				return error ? [] : [{ type: "categories", id: "LIST" }];
			},
		}),
		getCategoriesSalesOrderBreed: build.query<CategoriesSalesOrderBreed[], Record<string, any>>({
			query: (query) => `${url}/sales-order-breed?${qs.stringify(query)}`,
		}),
		getCategoriesBreedInByFarm: build.query<DataCategoriesType[], Record<string, any>>({
			query: (query) => `${url}/breed-in-by-farm?${qs.stringify(query)}`,
		}),
	}),
});
export const {
	useGetCategoriesBreedInByFarmQuery,
	useLazyGetCategoriesBreedInByFarmQuery,
	useLazyGetCategoriesSalesOrderBreedQuery,
	useGetCategoriesQuery,
	useGetCategoriesSellQuery,
	useLazyGetCategoriesSellQuery,
	useLazyGetCategoriesQuery,
	useCreateCategoriesMutation,
	useUpdateCategoriesMutation,
	useDeleteCategoriesMutation,
} = categoriesApi;
