export enum StatusSalesOrder {
	Draft = "Draft",
	Submit = "Submit",
	Complete = "Complete",
	Cancel = "Cancel",
}

export const getColorStatusSalesOrder = (status: StatusSalesOrder) => {
	const color = {
		Draft: "warning",
		Submit: "processing",
		Complete: "success",
		Invoice: "red",
		Cancel: "red",
	};

	return color[status];
};
