import dayjs from "dayjs";
import { useState } from "react";
import * as XLSX from "xlsx";

export const useImportExcel = () => {
	const [dataExcel, setDataExcel] = useState<any[]>([]);
	const [message, setMessage] = useState<string>("");

	const importExcel = (file: any, nameSheet: string, header: string[]) => {
		const fileReader = new FileReader();

		fileReader.onload = async (event: any) => {
			try {
				const { result } = event.target;
				const workbook = XLSX.read(result, { type: "binary", cellDates: true });
				const sheetNameList = Object.keys(workbook.Sheets);
				const sheetOne = sheetNameList.length ? sheetNameList[0] : "Sheet1";
				const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheetOne], { header, raw: false, dateNF: 'yyyy"."mm"."dd' });
				// const workbook = XLSX.read(result, { type: "binary", cellDates: true });
				// const data = XLSX.utils.sheet_to_json(workbook.Sheets["Tempature"], { header, raw: false, dateNF: "YYYY-MM-DD" });
				data.shift();
				const newData = data?.map((item: any) => ({ ...item, quantity: Number(item.quantity.replace(",", "")), doc_date: dayjs(item.doc_date).format("YYYY-MM-DD") }));

				setDataExcel(newData);
				setMessage("Success");
			} catch (e) {
				setMessage("Error");
			}
		};
		fileReader.readAsBinaryString(file);
	};

	return { dataExcel, message, importExcel };
};
