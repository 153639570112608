import { CaretDownOutlined, LogoutOutlined, UnlockOutlined, UserOutlined } from "@ant-design/icons";
import { Dropdown, Space, Typography } from "antd";
// import { useTranslation } from "react-i18next";
import type { MenuProps } from "antd";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "store";
import { removeUser } from "slice";
import { api } from "api";

const { Text } = Typography;

const HrefCustom = styled.a``;

export const SectionRight = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const user = useAppSelector((state) => state.user);

	const handleSignOut = () => {
		dispatch(api.util.resetApiState());
		dispatch(removeUser());
		navigate("/auth/login");
	};
	const handleChangePassword = () => {
		navigate("/auth/change-password");
	};

	const items: MenuProps["items"] = [
		{
			key: "change",
			icon: <UnlockOutlined />,
			label: "Password Change",
			onClick: handleChangePassword,
		},
		{
			key: "logout",
			icon: <LogoutOutlined />,
			label: "Logout",
			onClick: handleSignOut,
		},
	];
	return (
		<Space>
			<Dropdown menu={{ items }} trigger={["click"]}>
				<HrefCustom onClick={(e) => e.preventDefault()}>
					<Space style={{ whiteSpace: "nowrap", cursor: "pointer" }}>
						<UserOutlined style={{ color: "black" }} />
						<Text strong>{user?.fullname}</Text>
						<CaretDownOutlined style={{ color: "#9c9c9c" }} />
					</Space>
				</HrefCustom>
			</Dropdown>
		</Space>
	);
};
