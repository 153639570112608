import { Button, Space, Table } from "antd";
import { OBJ_COLOR } from "commons/constants";
import { CardComponent } from "components";
import moment from "moment";
import { useState } from "react";
import { ReportModal } from "./components";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useGetFarmsReportQuery } from "api";
import { ImportTemplate } from "./components/ImportTemplate";
import { SellModal } from "./components/SellModal";

export const CreateReport = () => {
	const [visible, setVisible] = useState(false);
	const [visibleSell, setVisibleSell] = useState(false);
	const [type, setType] = useState("");
	const { data: DATA, isLoading } = useGetFarmsReportQuery(undefined);
	const [dataModal, setDataModal] = useState<FarmsReport>();

	const handleOnClickOutButton = (value: any) => {
		setVisible(true);
		setType("OUT");
		setDataModal(value);
	};

	const handleOnClickInButton = (value: any) => {
		setVisible(true);
		setType("IN");
		setDataModal(value);
	};

	const handleOnClickBackButton = (value: any) => {
		setVisible(true);
		setType("CUT-OFF");
		setDataModal(value);
	};
	const handleOnClickSellButton = (value: any) => {
		setVisibleSell(true);
		setDataModal(value);
	};

	const handleOnClickOutTranButton = (value: any) => {
		setVisible(true);
		setType("OUT-TRAN");
		setDataModal(value);
	};

	const handleOnClickInTranButton = (value: any) => {
		setVisible(true);
		setType("IN-TRAN");
		setDataModal(value);
	};

	return (
		<CardComponent title={"Create Report"} extra={<ImportTemplate />}>
			<Table
				pagination={{
					showSizeChanger: true,
					hideOnSinglePage: true,
				}}
				dataSource={DATA?.map((item, key) => ({ ...item, key: key + 1 }))}
				loading={isLoading}
				scroll={{ x: true }}
				style={{ whiteSpace: "nowrap" }}>
				<Table.Column title="STT" dataIndex="key" align="center" width={50} />
				<Table.Column<FarmsReport>
					title="Code"
					render={(_, record) => {
						return record.farm_code;
					}}
				/>
				<Table.Column<FarmsReport>
					title="Name"
					render={(_, record) => {
						return record.farm_name;
					}}
				/>
				<Table.Column<FarmsReport>
					title="Start Date"
					align="center"
					render={(_, record) => {
						return record.user_farm.start_date;
					}}
				/>
				<Table.Column<FarmsReport>
					title="End Date"
					align="center"
					render={(_, record) => {
						return record.user_farm.end_date;
					}}
				/>
				<Table.Column<FarmsReport>
					title="Last Date"
					align="center"
					render={(_, record) => {
						return record.last_submit ? moment(record.last_submit).format("YYYY-MM-DD hh:mm:ss") : "-";
					}}
				/>
				<Table.Column<FarmsReport>
					title="Active"
					align="center"
					render={(_, record) => {
						return record.active ? <CheckOutlined style={{ color: OBJ_COLOR.SUCCESS }} /> : <CloseOutlined style={{ color: OBJ_COLOR.DANGER }} />;
					}}
				/>
				<Table.Column<FarmsReport>
					align="center"
					width={270}
					render={(_, record) => {
						return (
							<Space>
								<Button onClick={() => handleOnClickOutButton(record)} style={{ background: OBJ_COLOR.INFO }} type="primary">
									OUT
								</Button>
								<Button onClick={() => handleOnClickInButton(record)} style={{ background: OBJ_COLOR.SUCCESS }} type="primary">
									IN
								</Button>
								<Button onClick={() => handleOnClickBackButton(record)} style={{ background: OBJ_COLOR.DANGER }} type="primary">
									TRẢ LẠI
								</Button>
								<Button onClick={() => handleOnClickSellButton(record)} style={{ background: OBJ_COLOR.WARNING }} type="primary">
									SELL
								</Button>
							</Space>
						);
					}}
				/>
				<Table.Column<FarmsReport>
					align="center"
					width={250}
					render={(_, record) => {
						return (
							<Space>
								<Button onClick={() => handleOnClickOutTranButton(record)} style={{ background: OBJ_COLOR.INFO }} type="primary">
									OUT_TRAN
								</Button>
								<Button onClick={() => handleOnClickInTranButton(record)} style={{ background: OBJ_COLOR.SUCCESS }} type="primary">
									IN_TRAN
								</Button>
							</Space>
						);
					}}
				/>
			</Table>
			<ReportModal dataModal={dataModal} visible={visible} setVisible={setVisible} type={type} />
			<SellModal dataModal={dataModal} visible={visibleSell} setVisible={setVisibleSell}/>
		</CardComponent>
	);
};
