import React from "react";

import { MenuOutlined } from "@ant-design/icons";

import { Drawer, Button, Typography } from "antd";

const { Text } = Typography;

const Title = () => (
	<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
		<Text>G LinkFarm</Text>
	</div>
);

interface NavLinkMobileProps {
	children: JSX.Element;
	isVisible: boolean;
	setIsVisible: (boolean: boolean) => void;
}

const NavLinkMobile = ({ children, isVisible, setIsVisible }: NavLinkMobileProps) => {
	return (
		<>
			<Button icon={<MenuOutlined />} onClick={() => setIsVisible(true)} />
			<Drawer title={<Title />} placement="left" onClose={() => setIsVisible(false)} open={isVisible} width={280}>
				{children}
			</Drawer>
		</>
	);
};

export default NavLinkMobile;
