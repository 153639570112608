export const COLOR = ["magenta", "green", "volcano", "orange", "gold", "lime", "red", "cyan", "blue", "geekblue", "purple"];

export const OBJ_COLOR = {
	SUCCESS: "#45B148",
	SUCCESS_LIGHT: "#45B1481A",
	INFO: "#1791FF",
	INFO_LIGHT: "#1791FF1A",
	WARNING: "#FBAE16",
	WARNING_LIGHT: "#FBAE161A",
	DANGER: "#FF4D4F",
	DANGER_LIGHT: "#FF4D4F1A",
	SECONDARY: "#8C8C8C",
	SECONDARY_LIGHT: "#8C8C8C1A",
};

export const PATHS = [
	{
		name: "Create report",
		path: "/create-report",
		role: "staff",
	},
	{
		name: "Sell Reporting",
		path: "/sell-report",
		role: "staff",
	},
	{
		name: "Farm Reporting",
		path: "/farm-report",
		role: "staff",
	},
	{
		name: "Void transation",
		path: "/voi-transation",
	},
	{
		name: "Sell transation",
		path: "/sell-transation",
	},
	{
		name: "Categories",
		path: "/categories",
		children: [
			{
				name: "List Breed",
				path: "breed",
			},
			{
				name: "List Farm",
				path: "farm",
			},
			{
				name: "List Feed",
				path: "feed",
			},
			{
				name: "List Medicine",
				path: "medicine",
			},
			{
				name: "Chicken Loss",
				path: "chicken-loss",
			},
		],
	},
	{
		name: "List users",
		path: "/user-list",
	},
	{
		name: "Sales order",
		path: "/sales-order",
		role: "sale",
	},
];

export const enum UserRole {
	Staff = "Staff",
	Accountant = "Accountant",
	Administrator = "Administrator",
	Sale = "Sale",
}


export const BASE_URL_API = process.env.REACT_APP_BASE_URL || "";