import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, Row, Table, notification } from "antd";
import { useDeletePigScaleByFarmIdMutation, useGetPigScaleQuery } from "api/pigScaleApi";
import { OBJ_COLOR } from "commons/constants";
import { CardComponent } from "components";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import { useState } from "react";

const PigScale = () => {
    const [form] = Form.useForm();

    const [params, setParams] = useState<Record<string, any>>({});

    const { data, isLoading } = useGetPigScaleQuery(params);
    const [deletePigScaleByFarmId, { isLoading: isLoadingDeletePigScale }] = useDeletePigScaleByFarmIdMutation();

    const handleReset = () => {
        setParams({});
        form.resetFields();
    };

    const onFinish = (values: { farmId: string; docDate: string }) => {
        const { farmId, docDate } = values;
        setParams({ farmId: farmId ? farmId : undefined, docDate: docDate ? dayjs(docDate).format("YYYY-MM-DD") : undefined })
    };

    const handleDelete = async (farmId: string) => {
        try {
            await deletePigScaleByFarmId(farmId).unwrap();

            notification.success({
                message: "Success"
            });
        } catch (error) {
            notification.error({
                message: "Error"
            });
        }
    }

    return (
        <CardComponent title={"Farms Reporting"}>
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={[16, 0]} align="bottom">
                    <Col xs={12} xl={5}>
                        <Form.Item label="Farm ID" name="farmId">
                            <Input placeholder="Farm ID" />
                        </Form.Item>
                    </Col>
                    <Col xs={12} xl={5}>
                        <Form.Item label="Doc Date" name="docDate">
                            <DatePicker format={"DD-MM-YYYY"} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col xs={12} xl={5}>
                        <Form.Item>
                            <Button style={{ width: "100%" }} type="primary" htmlType="submit">
                                Search
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col xs={12} xl={5}>
                        <Form.Item>
                            <Button style={{ width: "100%", background: OBJ_COLOR.WARNING }} type="primary" onClick={handleReset}>
                                Reset
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={data?.map((item, key) => ({ ...item, key: key + 1 }))}
                loading={isLoading || isLoadingDeletePigScale}
                scroll={{ x: true }}
                style={{ whiteSpace: "nowrap" }}
            >
                <Table.Column<PigScale> title="No." dataIndex={"key"} />
                <Table.Column<PigScale> title="Row ID" dataIndex={"rowId"} />
                <Table.Column<PigScale> title="Document No" dataIndex={"documentNo"} />
                <Table.Column<PigScale> title="Farm ID" dataIndex={"farmId"} />
                <Table.Column<PigScale> title="Farm Name" dataIndex={"farmName"} />
                <Table.Column<PigScale> title="Pig Scale ID" dataIndex={"pigScaleID"} />
                <Table.Column<PigScale> title="Do Num" dataIndex={"doNum"} />
                <Table.Column<PigScale> title="Doc Date" dataIndex={"docDate"} render={(docDate: string) => dayjs(docDate).format("DD-MM-YYYY")} />
                <Table.Column<PigScale> title="Truck ID" dataIndex={"truckId"} />
                <Table.Column<PigScale> title="Stakeholder ID" dataIndex={"stakeholderId"} />
                <Table.Column<PigScale> title="Stakeholder Name" dataIndex={"stakeholderName"} />
                <Table.Column<PigScale> title="Address" dataIndex={"address"} />
                <Table.Column<PigScale> title="Phone No" dataIndex={"phoneNo"} />
                <Table.Column<PigScale> title="Doc Type" dataIndex={"docType"} />
                <Table.Column<PigScale> title="Type" dataIndex={"type"} />
                <Table.Column<PigScale> title="Item Code" dataIndex={"itemCode"} />
                <Table.Column<PigScale> title="Sotai" dataIndex={"sotai"} />
                <Table.Column<PigScale> title="Quantity Pig" dataIndex={"qtyPig"} />
                <Table.Column<PigScale> title="Weight Pig" dataIndex={"weightPig"} />
                <Table.Column<PigScale> title="Weight Pig Round" dataIndex={"weightPigRound"} />
                <Table.Column<PigScale> title="Scale Date" dataIndex={"scaleDate"} render={(scaleDate: string) => dayjs(scaleDate).format("HH:mm:ss DD-MM-YYYY")} />
                <Table.Column<PigScale> title="Weight Bag" dataIndex={"weightBag"} />
                <Table.Column<PigScale> title="Weight Avg" dataIndex={"weightAvg"} />
                <Table.Column<PigScale> title="Printed Number" dataIndex={"printedNumber"} />
                <Table.Column<PigScale> title="Printed Date" dataIndex={"printedDate"} render={(printedDate: string) => dayjs(printedDate).format("HH:mm:ss DD-MM-YYYY")} />
                <Table.Column<PigScale> title="Scale By" dataIndex={"scaleBy"} />
                <Table.Column<PigScale> title="Notes1" dataIndex={"notes1"} />
                <Table.Column<PigScale> title="Action" dataIndex={"farmId"} fixed="right" render={(farmId: string) => <Button type="primary" danger icon={<DeleteOutlined />} onClick={() => handleDelete(farmId)}>Delete</Button>} />
            </Table>
        </CardComponent>
    )
}

export default PigScale;