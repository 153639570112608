import { Checkbox, DatePicker, Form, Input, Modal, notification, Select, Space } from "antd";
import { useCreateFarmsMutation, useUpdateFarmsMutation } from "api";
import dayjs from "dayjs";
import { useEffect } from "react";

interface ReportModalProps {
	visible: boolean;
	dataModal?: DataCategoriesFarmType | undefined;
	setVisible: (visible: boolean) => void;
	title: string;
}
export const FarmModal = ({ title, visible, setVisible, dataModal }: ReportModalProps) => {
	const [form] = Form.useForm();
	const [createFarms, { isLoading: isLoadingCreate }] = useCreateFarmsMutation();
	const [updateFarms, { isLoading: isLoadingUpdate }] = useUpdateFarmsMutation();
	useEffect(() => {
		if (!visible) return;

		form.resetFields();

		if (dataModal) {
			form.setFieldsValue({
				bu_code: dataModal.bu_code,
				area: dataModal.area,
				farm_name: dataModal.farm_name,
				start_date: dayjs(dataModal.start_date),
				end_date: dayjs(dataModal.end_date),
				active: dataModal.active,
			});
		}
	}, [visible, form, dataModal]);

	const formatTime = (time: any) => {
		return dayjs(time as string).format("YYYY-MM-DD");
	};

	const handleOk = () => {
		form.submit();
	};

	const onFinish = async (value: any) => {
		const active = !value.active ? false : value.active;
		try {
			if (!dataModal) {
				await createFarms({ ...value, start_date: formatTime(value.start_date), end_date: formatTime(value.end_date), active }).unwrap();
			} else {
				await updateFarms({ ...value, start_date: formatTime(value.start_date), end_date: formatTime(value.end_date), active, id: dataModal.id }).unwrap();
			}
			setVisible(false);
			notification.success({
				message: "Success",
			});
		} catch (e: any) {
			notification.error({
				message: e.data.message,
			});
		}
	};

	return (
		<Modal width={650} maskClosable={false} title={title} open={visible} onOk={handleOk} okText="Submit" onCancel={() => setVisible(false)} confirmLoading={isLoadingCreate || isLoadingUpdate}>
			<Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
				<Form.Item label="Bu" name="bu_code" rules={[{ required: true, message: "Please input your username!" }]}>
					<Select style={{ width: "100%" }} options={[{ value: "VNM", label: "Việt Nam" }]} allowClear placeholder="---Seclect Bu---" />
				</Form.Item>
				<Form.Item label="Area" name="area" rules={[{ required: true, message: "Please input your username!" }]}>
					<Select
						style={{ width: "100%" }}
						options={[
							{ value: "Miền Nam", label: "Miền Nam" },
							{ value: "Miền Bắc", label: "Miền Bắc" },
						]}
						allowClear
						placeholder="---Seclect Item---"
					/>
				</Form.Item>
				<Form.Item label="Name" name="farm_name" rules={[{ required: true, message: "Please input your username!" }]}>
					<Input placeholder="Name" />
				</Form.Item>
				<Form.Item label="Start Date" name="start_date" rules={[{ required: true, message: "Please input your username!" }]}>
					<DatePicker style={{ width: "100%" }} format={"YYYY-MM-DD"} />
				</Form.Item>
				<Form.Item label="End Date" name="end_date" rules={[{ required: true, message: "Please input your username!" }]}>
					<DatePicker style={{ width: "100%" }} format={"YYYY-MM-DD"} />
				</Form.Item>
				<Space align="baseline" style={{ marginLeft: 10 }}>
					Active
					<Form.Item name="active" valuePropName="checked">
						<Checkbox />
					</Form.Item>
				</Space>
			</Form>
		</Modal>
	);
};
