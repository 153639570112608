import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, InputNumber, Modal, notification, Row, Select, Space, Typography } from "antd";
import { useCreateMasterSellReportMutation, useGetCategoriesBreedInByFarmQuery, useGetCategoriesQuery, useLazyGetCategoriesSellQuery } from "api";
import { OBJ_COLOR } from "commons/constants";
import { useEffect } from "react";
import { useAppSelector } from "store";
import styled from "styled-components";
import "dayjs/locale/zh-cn";
import dayjs from "dayjs";

const { Text } = Typography;
interface SellModalProps {
	visible: boolean;
	setVisible: (visible: boolean) => void;
	dataModal: FarmsReport | undefined;
}
const TextCustoms = styled(Text)`
	display: inline-block;
	margin-bottom: 5px;
	margin-left: 10px;
`;

export const SellModal = ({ visible, setVisible, dataModal }: SellModalProps) => {
	const [form] = Form.useForm();
	// const [formList] = Form.List;
	const user = useAppSelector((state) => state.user);
	// const { data: BreedList } = useGetCategoriesQuery({ datatype_id: 1, active: true, pageSize: 1000 });
	const [getCategoriesSell, { data: BreedList, error: ErrorApi }] = useLazyGetCategoriesSellQuery();
	const [createMasterSellReport, { isLoading }] = useCreateMasterSellReportMutation();

	const { data: dataBreedInByFarm } = useGetCategoriesBreedInByFarmQuery({ farmCode: dataModal?.farm_code }, { skip: !dataModal });


	useEffect(() => {
		if (!visible) return;

		form.resetFields();

	}, [visible, form, getCategoriesSell, dataModal]);

	useEffect(() => {
		if (!ErrorApi) return;

		setVisible(false);
	}, [ErrorApi, setVisible]);

	const handleOk = () => {
		form.submit();
	};

	const onFinish = async (value: any) => {
		const { date, ...rest } = value;
		const newData = Object.fromEntries(Object.entries(rest).filter(([keys, index]) => (index as any[])[0].itemCode));
		const data = Object.entries(newData).map((item: any[]) => {
			const itemData = item[1].map((value: any[]) => ({ ...value, categoryCode: item[0] }));
			return itemData;
		});
		const DATA = {
			doc_date: dayjs(date as string).format("YYYY-MM-DD"),
			farm_code: dataModal?.farm_code,
			user_id: user?.id,
			source: "SELL",
			data: data[0],
		};

		try {
			await createMasterSellReport(DATA).unwrap();
			setVisible(false);
			notification.success({
				message: "Success",
			});
		} catch (e: any) {
			notification.error({
				message: e.data.message,
			});
		}
	};

	return (
		<Modal
			maskClosable={false}
			width={1100}
			title={
				<Space direction="vertical" size={0} style={{ whiteSpace: "break-spaces" }}>
					<Text style={{ fontWeight: 700, fontSize: 20, color: OBJ_COLOR.SUCCESS }}>BÁO CÁO XUẤT BÁN </Text>
					<Text style={{ fontWeight: "normal", fontSize: 16 }}>Tên trại: {`${dataModal?.farm_code} - ${dataModal?.farm_name}`}</Text>
				</Space>
			}
			bodyStyle={{ padding: 0 }}
			open={visible}
			onOk={handleOk}
			okText="Submit"
			onCancel={() => setVisible(false)}
			confirmLoading={isLoading}>
			<Form form={form} name="basic" layout="vertical" onFinish={onFinish} initialValues={{ breed: [""] }} autoComplete="off">
				<Row gutter={16}>
					<Col span={24}>
						<Text>Người bán: {user?.fullname}</Text>
						<Space style={{ width: "100%" }}>
							<Text>Ngày bán:</Text>
							<Form.Item style={{ margin: 0, width: 200 }} name="date" rules={[{ required: true, message: "Please input your date!" }]}>
								<DatePicker disabledDate={(date) => date.isAfter(dayjs())} style={{ borderWidth: 0, borderBottomWidth: 1, borderRadius: 0, width: "100%", boxShadow: "none" }} />
							</Form.Item>
						</Space>
					</Col>
					<Col span={12}></Col>
				</Row>
				<Form.List name="breed">
					{(fields, { add, remove }) => (
						<>
							<Space align="center" style={{ width: "100%", justifyContent: "space-between", display: "flex" }}>
								<TextCustoms strong style={{ color: OBJ_COLOR.INFO }}>
									CON GIỐNG
								</TextCustoms>
								<Form.Item style={{ margin: 0 }}>
									<Button
										style={{ padding: "0 10px", borderColor: OBJ_COLOR.INFO, color: OBJ_COLOR.INFO }}
										type="dashed"
										onClick={() => add()}
										block
										icon={<PlusOutlined />}></Button>
								</Form.Item>
							</Space>
							{fields.map(({ key, name, ...restField }) => (
								<Row key={key} gutter={16}>
									<Col span={10}>
										<Form.Item
											{...restField}
											name={[name, "itemCode"]}
											rules={[
												{
													required: true,
													message: "Please select code",
												},
											]}>
											<Select
												showSearch
												style={{ width: "100%" }}
												options={dataBreedInByFarm?.map((item, key) => ({ key: key, label: `${item?.code} ${item?.name ? `- ${item?.name} - DVT:${item.uom}` : ""} `, value: item.code }))}
												allowClear
												placeholder="--- Chọn con giống ---"
											/>
										</Form.Item>
									</Col>
									<Col span={3}>
										<Form.Item
											{...restField}
											name={[name, "itemType"]}
											rules={[
												{
													required: true,
													message: "Select type",
												},
											]}>
											<Select
												style={{ width: "100%" }}
												options={[
													{ value: 1, label: "Loại 1" },
													{ value: 2, label: "Loại 2" },
												]}
												allowClear
												placeholder="Loại gà"
											/>
										</Form.Item>
									</Col>
									<Col span={3}>
										<Form.Item
											{...restField}
											name={[name, "gender"]}
											rules={[
												{
													required: true,
													message: "Select gender",
												},
											]}>
											<Select
												style={{ width: "100%" }}
												options={[
													{ value: "M", label: "Trống" },
													{ value: "F", label: "Mái" },
													{ value: "U", label: "Xô" },
												]}
												allowClear
												placeholder="Giới tính"
											/>
										</Form.Item>
									</Col>
									<Col span={3}>
										<Form.Item
											{...restField}
											name={[name, "qty"]}
											rules={[
												{
													required: true,
													message: "Input quantity",
												},
											]}>
											<InputNumber style={{ width: "100%" }} placeholder="Số lượng" controls={false} />
										</Form.Item>
									</Col>
									<Col span={4}>
										<Form.Item
											{...restField}
											name={[name, "weight"]}
											rules={[
												{
													required: true,
													message: "Input weight",
												},
											]}>
											<InputNumber style={{ width: "100%" }} placeholder="Trọng lượng (KG)" controls={false} />
										</Form.Item>
									</Col>
									<Col span={1}>{fields.length > 1 ? <MinusCircleOutlined style={{ color: OBJ_COLOR.DANGER }} onClick={() => remove(name)} /> : null}</Col>
								</Row>
							))}
						</>
					)}
				</Form.List>
			</Form>
		</Modal>
	);
};
