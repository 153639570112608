import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { ConfigProvider, Empty } from "antd";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "store";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
	<React.StrictMode>
		<ConfigProvider
			renderEmpty={() => <Empty style={{ background: "unset" }} />}
			theme={{
				token: {
					colorPrimary: "#45B148",
				},
			}}>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<App />
				</PersistGate>
			</Provider>
		</ConfigProvider>
	</React.StrictMode>
);
