import { api, providesList } from ".";
import qs from "query-string";

const url = "/master-data";

const masterDataApi = api.injectEndpoints({
	endpoints: (build) => ({
		getVoidTrans: build.query<VoidtransPage, Record<string, any> | undefined>({
			query: (query) => {
				if (query) {
					return `${url}/void-trans?${qs.stringify(query)}`;
				}
				return url;
			},
			providesTags: (result) => providesList(result?.items, "void-trans"),
		}),
		getDetailMasterData: build.query<FarmReportDetail[], Record<string, any>>({
			query: (query) => {
				const { id } = query;
				if (query) {
					return `/detail-data/master-data-detail/${id}`;
				}
				return url;
			},
			providesTags: (result) => providesList(result, "void-trans"),
		}),
		// getFarmList: build.query<FarmListPage, Record<string, any> | undefined>({
		// 	query: (query) => {
		// 		if (query) {
		// 			return `${url}/list?${qs.stringify(query)}`;
		// 		}
		// 		return url;
		// 	},
		// 	providesTags: (result) => providesList(result?.items, "farm-list"),
		// }),
		// getFarmReportExport: build.query<FarmReportExport[], Record<string, any> | undefined>({
		// 	query: (query) => {
		// 		if (query) {
		// 			return `${url}/export?${qs.stringify(query)}`;
		// 		}
		// 		return url;
		// 	},
		// }),
		recall: build.mutation<void, Record<string, any>>({
			query: (data) => {
				const { id } = data;
				return {
					url: `${url}/recall/${id}`,
					method: "POST",
					body: data,
				};
			},
		}),
		VoidTrans: build.mutation<void, Record<string, any>>({
			query: (data) => {
				const { id } = data;
				return {
					url: `${url}/void-trans/${id}`,
					method: "POST",
					body: data,
				};
			},
		}),
		createReport: build.mutation<void, Record<string, any>>({
			query: (data) => {
				return {
					url: `${url}`,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: (result, error, { id }) => {
				return error ? [] : [{ type: "farm-list", id }];
			},
		}),
		createImportData: build.mutation<void, Record<string, any>>({
			query: (data) => {
				return {
					url: `${url}/import`,
					method: "POST",
					body: data,
				};
			},
		}),
	}),
});
export const {
	useGetVoidTransQuery,
	useLazyGetVoidTransQuery,
	// useLazyGetFarmReportExportQuery,
	useGetDetailMasterDataQuery,
	// useGetFarmListQuery,
	useCreateReportMutation,
	useCreateImportDataMutation,
	useRecallMutation,
	useVoidTransMutation,
} = masterDataApi;
