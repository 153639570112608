import { api, providesList } from ".";
import qs from "query-string";

const url = "/upload-file";

const userApi = api.injectEndpoints({
	endpoints: (build) => ({
		uploadFileToServer: build.mutation<{ path: string }, any>({
			query: (data) => {
				return {
					url,
					method: "POST",
					body: data,
				};
			},
		}),
	}),
});
export const { useUploadFileToServerMutation } = userApi;
