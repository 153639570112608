import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Space, Table } from "antd";
import { OBJ_COLOR } from "commons/constants";

interface TableUserProps {
	handleOnClickEdit: (value: User) => void;
	handleOnClickAssign: (id: number) => void;
	handleOnChangePagination: (id: any) => void;
	showDeleteConfirm: (id: any) => void;
	users: User[] | undefined;
	isLoading: boolean;
	pagination: { current: number; pageSize: number; total: number; showSizeChanger: boolean } | undefined;
}
export const TableUser = ({ handleOnClickEdit, handleOnClickAssign, users, isLoading, pagination, handleOnChangePagination, showDeleteConfirm }: TableUserProps) => {
	return (
		<>
			<Table
				onChange={handleOnChangePagination}
				pagination={pagination}
				dataSource={users?.map((item, key) => ({ ...item, key: key + 1 }))}
				loading={isLoading}
				scroll={{ x: true }}
				style={{ whiteSpace: "nowrap" }}>
				<Table.Column title="STT" dataIndex="key" align="center" width={50} />
				<Table.Column<User>
					title="Fullname"
					render={(_, record) => {
						return record.fullname;
					}}
				/>
				<Table.Column<User>
					title="Username"
					render={(_, record) => {
						return record.username;
					}}
				/>
				<Table.Column<User>
					title="Email"
					align="center"
					render={(_, record) => {
						return record.email;
					}}
				/>
				<Table.Column<User>
					title="Phone"
					align="center"
					render={(_, record) => {
						return record.phone;
					}}
				/>
				<Table.Column<User>
					title="Role"
					align="center"
					render={(_, record) => {
						return record.role;
					}}
				/>
				<Table.Column<User>
					title="Area"
					align="center"
					render={(_, record) => {
						return record.area || "-";
					}}
				/>
				<Table.Column<User>
					title="Active"
					align="center"
					render={(_, record) => {
						return record.active ? <CheckOutlined style={{ color: OBJ_COLOR.SUCCESS }} /> : <CloseOutlined style={{ color: OBJ_COLOR.DANGER }} />;
					}}
				/>
				<Table.Column<User>
					width={270}
					render={(_, record) => {
						return (
							<Space>
								<Button onClick={() => handleOnClickEdit(record)} style={{ background: OBJ_COLOR.SUCCESS }} type="primary">
									Edit
								</Button>
								<Button onClick={() => showDeleteConfirm(record)} style={{ background: OBJ_COLOR.DANGER }} type="primary">
									Delete
								</Button>
								{["Staff", "Sale"].includes(record.role) && (
									<Button onClick={() => handleOnClickAssign(record.id)} style={{ background: OBJ_COLOR.INFO }} type="primary">
										Assign farm
									</Button>
								)}
							</Space>
						);
					}}
				/>
			</Table>
		</>
	);
};
