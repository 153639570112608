import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "store";

export function providesList<R extends { id: string | number }[], T extends string>(resultsWithIds: R | undefined, tagType: T) {
	return resultsWithIds ? [{ type: tagType, id: "LIST" }, ...resultsWithIds.map(({ id }) => ({ type: tagType, id }))] : [{ type: tagType, id: "LIST" }];
}

export const api = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			const user = (getState() as RootState).user;
			// const language = localStorage.getItem("i18nextLng");
			// headers.set("accept-language", language!);
			if (user) {
				headers.set("authorization", `Bearer ${user.access_token}`);
				headers.set("api-key", "tf+s~SPX{j<S={Ji+b4nL&(egyo={$~q");
			}
			return headers;
		},
	}),

	tagTypes: [
		"users",
		"categories",
		"farm",
		"user-farm",
		"void-trans",
		"farm-list",
		"farm-report",
		"master-sell",
		"categories-sell",
		"sell-trans",
		"pig-scale",
		"sales-order",
		"sales-order-detail",
		"scale",
	],

	endpoints: () => ({}),
});
