import { Descriptions, Drawer, Empty, Flex, Grid, Image, Skeleton, Tag, Typography } from "antd";
import { useGetOneSalesOrderQuery } from "api/sales-order/sales-order-api";
import { OBJ_COLOR } from "commons/constants";
import { StatusSalesOrder, getColorStatusSalesOrder } from "commons/constants/sales-order";
import { dateFormat, getUrlImage } from "commons/constants/util";
import SalesOrderDetailTable from "./SalesOrderDetailTable";

const { useBreakpoint } = Grid;

interface SeeSalesOrderDrawerProps {
	open: boolean;
	onClose: () => void;
	salesOrderId: number;
}

const SeeSalesOrderDrawer = ({ open, onClose, salesOrderId }: SeeSalesOrderDrawerProps) => {
	const screens = useBreakpoint();

	const { data: dataSalesOrder, isFetching } = useGetOneSalesOrderQuery(salesOrderId, { skip: !salesOrderId, refetchOnMountOrArgChange: true });

	if (!dataSalesOrder) return null;

	const { customerName, customerId, custPhone, orderDate, farmCode, farmName, shipDate, shipAddress, driver, driverPhone, status, truckId, pathImage } = dataSalesOrder;

	const pathImageList = pathImage.split("|").filter((src) => src);

	return (
		<Drawer title={`Thông tin đơn hàng`} open={open} width={"100%"} onClose={onClose}>
			<Flex vertical gap={20} style={{ width: screens.xxl ? "60%" : screens.xl ? "90%" : "100%", margin: "auto" }}>
				<Flex vertical gap={20}>
					<Flex justify="space-between" align="center">
						<Typography.Text style={{ color: OBJ_COLOR.SUCCESS, fontSize: 16, fontWeight: 600 }}>Thông tin đơn hàng</Typography.Text>
					</Flex>
					<Skeleton active loading={isFetching}>
						<div
							style={{
								padding: 10,
								border: "1px solid #efefef",
								borderRadius: 8,
							}}>
							<Descriptions column={{ xs: 1, xl: 3 }} size="small" style={{ fontWeight: 500 }}>
								<Descriptions.Item label="Mã đơn hàng">
									<Typography.Text type="success" strong>
										{salesOrderId}
									</Typography.Text>
								</Descriptions.Item>
								<Descriptions.Item label="Ngày tạo">{dateFormat(orderDate)}</Descriptions.Item>
								<Descriptions.Item label="Ngày giao hàng">{dateFormat(shipDate)}</Descriptions.Item>
								<Descriptions.Item label="Trại">{`[${farmCode}]: ${farmName}`}</Descriptions.Item>
								<Descriptions.Item label="Khách hàng">{`[${customerId}]: ${customerName}`}</Descriptions.Item>
								<Descriptions.Item label="SĐT khách hàng">{custPhone}</Descriptions.Item>
								<Descriptions.Item label="Địa chỉ giao hàng">{shipAddress}</Descriptions.Item>
								<Descriptions.Item label="Tên tài xế">{driver || "-"}</Descriptions.Item>
								<Descriptions.Item label="SĐT tài xế">{driverPhone || "-"}</Descriptions.Item>
								<Descriptions.Item label="Số xe">{truckId || "-"}</Descriptions.Item>
								<Descriptions.Item label="Trạng thái">{<Tag color={getColorStatusSalesOrder(status as StatusSalesOrder)}>{status}</Tag>}</Descriptions.Item>
							</Descriptions>
						</div>
					</Skeleton>
				</Flex>

				<Flex vertical>
					<Typography.Text style={{ color: OBJ_COLOR.SUCCESS, fontSize: 16, fontWeight: 600 }}>Hình ảnh</Typography.Text>
					<Flex gap={20} wrap="wrap" style={{ marginTop: 10 }}>
						{pathImageList.length ? (
							pathImageList.map((src) => <Image style={{ border: "1px solid rgb(239, 239, 239)", borderRadius: 6 }} width={100} height={100} src={getUrlImage(src)} />)
						) : (
							<Empty description="Không có hình ảnh" />
						)}
					</Flex>
				</Flex>

				<Flex vertical gap={20}>
					<Typography.Text style={{ color: OBJ_COLOR.SUCCESS, fontSize: 16, fontWeight: 600 }}>Thông tin con giống</Typography.Text>
					<SalesOrderDetailTable salesOrderId={salesOrderId} />
				</Flex>
			</Flex>
		</Drawer>
	);
};

export default SeeSalesOrderDrawer;
