import { api, providesList } from "api/baseApi";
import qs from "query-string";

const url = "/sales-order";

export const {
	useCreateSalesOderMutation,
	useUpdateSalesOderMutation,
	useGetSalesOrdersQuery,
	useGetOneSalesOrderQuery,
	useGetSalesOrderDetailQuery,
	useDeleteSalesOrderMutation,
	useLazyGetSalesOrderInvoiceQuery,
	useCancelSalesOrderMutation,
} = api.injectEndpoints({
	endpoints: (build) => ({
		createSalesOder: build.mutation<void, Record<string, any>>({
			query: (data) => {
				return {
					url,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: (result, error) => {
				return error ? [] : [{ type: "sales-order", id: "LIST" }];
			},
		}),
		updateSalesOder: build.mutation<void, Record<string, any>>({
			query: (body) => {
				const { id } = body;
				return {
					url: `${url}/${id}`,
					method: "PUT",
					body,
				};
			},
			invalidatesTags: (result, error, { id }) => {
				return error
					? []
					: [
							{ type: "sales-order", id },
							{ type: "sales-order", id: "LIST" },
							{ type: "sales-order-detail", id },
					  ];
			},
		}),
		getSalesOrders: build.query<ResponsePaginated<SalesOrder>, Record<string, any>>({
			query: (query) => `${url}?${qs.stringify(query)}`,
			providesTags: (result) => providesList(result?.items, "sales-order"),
		}),
		getOneSalesOrder: build.query<SalesOrder, number>({
			query: (salesOrderId) => `${url}/${salesOrderId}`,
			providesTags: (result, _, salesOrderId) => [{ type: "sales-order", id: salesOrderId }],
		}),
		getSalesOrderDetail: build.query<any[], number>({
			query: (salesOrderId) => `sales-order-detail/${salesOrderId}`,
			providesTags: (result, _, salesOrderId) => [{ type: "sales-order-detail", id: salesOrderId }],
		}),
		deleteSalesOrder: build.mutation<void, number>({
			query: (id) => {
				return {
					url: `${url}/${id}`,
					method: "DELETE",
				};
			},
			invalidatesTags: (_, error, id) => (error ? [] : [{ type: "sales-order", id }]),
		}),
		cancelSalesOrder: build.mutation<void, number>({
			query: (id) => {
				return {
					url: `${url}/cancel/${id}`,
					method: "PUT",
				};
			},
			invalidatesTags: (_, error, id) => (error ? [] : [{ type: "sales-order", id }]),
		}),
		getSalesOrderInvoice: build.query<{ UrlPDF: string }, number>({
			query: (salesOrderId) => `${url}/invoice?salesOrderId=${salesOrderId}`,
		}),
	}),
});
