import { CheckOutlined, CloseOutlined, DownloadOutlined, ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Input, Modal, notification, Space, Table } from "antd";
import { useDeleteFarmsMutation, useGetFarmsQuery, useLazyGetFarmsQuery } from "api";
import { OBJ_COLOR } from "commons/constants";
import { CardComponent } from "components";
import { useDebounce } from "hooks/useDebounce";
import { useExportExcel } from "hooks/useExportExcel";
import { ButtonComponent } from "pages/categories/components";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { FarmModal, RecycleModal } from "./components";

const { confirm } = Modal;

interface Pagination {
	current: number;
	pageSize: number;
	total: number;
	showSizeChanger: boolean;
}
const SpaceCustom = styled(Space)`
	justify-content: space-between;
	display: flex;
	margin-bottom: 20px;
	width: 100%;
	@media (max-width: 750px) {
		display: block;
		.wp-button {
			margin-top: 10px;
			width: 100%;
			display: flex;
			justify-content: space-between;
		}
	}
`;
export const FarmList = () => {
	const [visible, setVisible] = useState(false);
	const [visibleRecycle, setVisibleRecycle] = useState(false);
	const [dataModal, setDataModal] = useState<DataCategoriesFarmType | undefined>();
	const [titleModal, setTitleModal] = useState("");
	const [param, setParam] = useState({ pageNumber: 1, pageSize: 10 });
	const [search, setSearch] = useState<string>("");
	const [pagination, setPagination] = useState<Pagination>();
	const [deleteFarms] = useDeleteFarmsMutation();
	const debouncedValue = useDebounce(search, 500);
	const [getFarms, { data: DataFarm }] = useLazyGetFarmsQuery();
	const { message, exportExcel } = useExportExcel();
	const [isLoadingButton, setIsLoadingButton] = useState(false);

	const paramApi = useMemo(() => {
		const query = {
			...param,
		};
		if (!debouncedValue) return query;

		return {
			...query,
			search: debouncedValue,
		};
	}, [param, debouncedValue]);

	const { data: categories, isLoading, isFetching } = useGetFarmsQuery(paramApi);

	useEffect(() => {
		if (!categories) return;

		setPagination({
			current: param.pageNumber,
			pageSize: param.pageSize,
			total: categories.totalCount,
			showSizeChanger: true,
		});
	}, [categories, param]);

	const handleOnChangePagination = (value: any) => {
		setParam({ pageNumber: value.current, pageSize: value.pageSize });
	};
	const handleInputSearch = (value: string) => {
		setSearch(value);
		setParam((prev) => ({ ...prev, pageNumber: 1 }));
	};
	const handleOnClickEdit = (value: any) => {
		setDataModal(value);
		setVisible(true);
		setTitleModal("Edit Farm");
	};
	const showDeleteConfirm = (value: any) => {
		confirm({
			title: "Delete ?",
			icon: <ExclamationCircleFilled />,
			content: "Are you sure you want to delete this item?",
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk() {
				const FetchApi = async () => {
					try {
						await deleteFarms(value.id).unwrap();
						notification.success({
							message: "Success",
						});
					} catch (e: any) {
						notification.error({
							message: e.data.message,
						});
					}
				};
				FetchApi();
			},
		});
	};

	const handleOnClickRecycle = (value: any) => {
		setDataModal(value);
		setVisibleRecycle(true);
	};

	const handleOnClickCreate = () => {
		setVisible(true);
		setTitleModal("Create Farm");
		setDataModal(undefined);
	};

	const handleOnClickExport = async () => {
		setIsLoadingButton(true);

		try {
			const DataFarm = await getFarms({ pageSize: 1000 }).unwrap();
			const Heading: any = [{ code: "Code", farm_name: "Name", start_date: "Start Date", end_date: "End Date", area: "Area", active: "Active" }];
			const wscols: any = [{ wch: 15 }, { wch: 30 }, { wch: 12 }, { wch: 12 }, { wch: 10 }, { wch: 10 }];
			const header: any[] = [];

			const dataExport = DataFarm.items.reduce((unique: any, o: any) => {
				if (!unique.some((obj: any) => obj.code === o.farm_code)) {
					unique.push({
						code: o.farm_code,
						farm_name: o.farm_name,
						start_date: o.start_date,
						end_date: o.end_date,
						area: o.area,
						active: o.active ? "x" : "-",
					});
				}
				return unique;
			}, []);

			exportExcel({ Heading, wscols, header, dataExport, nameFile: "categories-farm" });
		} catch (error) {}
		setIsLoadingButton(false);
	};

	return (
		<>
			<CardComponent title={"Farm Management"}>
				<SpaceCustom>
					<Input placeholder="Search..." onChange={(e) => handleInputSearch(e.target.value)} />
					<Space className="wp-button">
						<Button loading={isLoadingButton} ghost type="primary" icon={<DownloadOutlined />} onClick={handleOnClickExport}>
							Export
						</Button>
						<Button onClick={handleOnClickCreate} style={{ background: OBJ_COLOR.SUCCESS }} type="primary" icon={<PlusOutlined />}>
							Create new
						</Button>
					</Space>
				</SpaceCustom>
				<Table
					onChange={handleOnChangePagination}
					pagination={pagination}
					dataSource={categories?.items.map((item, key) => ({ ...item, key: key + 1 }))}
					loading={isLoading || isFetching}
					scroll={{ x: true }}
					style={{ whiteSpace: "nowrap" }}>
					<Table.Column title="STT" dataIndex="key" align="center" width={50} />
					<Table.Column<DataCategoriesFarmType>
						title="Code"
						render={(_, record) => {
							return record.farm_code;
						}}
					/>
					<Table.Column<DataCategoriesFarmType>
						title="Name"
						render={(_, record) => {
							return record.farm_name;
						}}
					/>
					<Table.Column<DataCategoriesFarmType>
						title="Start Date"
						align="center"
						render={(_, record) => {
							return record.start_date;
						}}
					/>
					<Table.Column<DataCategoriesFarmType>
						title="End Date"
						align="center"
						render={(_, record) => {
							return record.end_date;
						}}
					/>
					<Table.Column<DataCategoriesFarmType>
						title="Area"
						align="center"
						render={(_, record) => {
							return record.area;
						}}
					/>
					<Table.Column<DataCategoriesFarmType>
						title="Active"
						align="center"
						render={(_, record) => {
							return record.active ? <CheckOutlined style={{ color: OBJ_COLOR.SUCCESS }} /> : <CloseOutlined style={{ color: OBJ_COLOR.DANGER }} />;
						}}
					/>
					<Table.Column<DataCategoriesFarmType>
						align="center"
						width={270}
						render={(_, record) => {
							return <ButtonComponent record={record} handleOnClickEdit={handleOnClickEdit} handleOnClickDelete={showDeleteConfirm} handleOnClickRecycle={handleOnClickRecycle} />;
						}}
					/>
				</Table>
			</CardComponent>
			<FarmModal visible={visible} setVisible={setVisible} dataModal={dataModal} title={titleModal} />
			<RecycleModal visible={visibleRecycle} setVisible={setVisibleRecycle} dataModal={dataModal} />
		</>
	);
};
