import { api } from "api/baseApi";
import qs from "query-string";

const url = "/scale";

export const { useGetOneScaleBySalesOrderQuery, useDeleteScaleMutation, useLazyGetReportScaleQuery } = api.injectEndpoints({
	endpoints: (build) => ({
		getReportScale: build.query<ReportScale[], Record<string, any>>({
			query: (query) => {
				return `${url}/report?${qs.stringify(query)}`;
			},
		}),
		getOneScaleBySalesOrder: build.query<
			ScaleBySalesOrder,
			{
				salesOrderId: number;
				salesOrderDetailId: number;
			}
		>({
			query: ({ salesOrderId, salesOrderDetailId }) => `${url}/${salesOrderId}/${salesOrderDetailId}`,
			providesTags: (result, _, { salesOrderId, salesOrderDetailId }) => [{ type: "scale", id: `${salesOrderId}${salesOrderDetailId}` }],
		}),
		deleteScale: build.mutation<void, string>({
			query: (scaleId) => ({ url: `${url}/${scaleId}`, method: "DELETE" }),
		}),
	}),
});
