import { Button, Space } from "antd";
import { OBJ_COLOR } from "commons/constants";

interface ButtonComponentProps {
	handleOnClickEdit: (value: DataCategoriesFarmType) => void;
	handleOnClickDelete: (value: DataCategoriesFarmType) => void;
	handleOnClickRecycle: (value: DataCategoriesFarmType) => void;
	record: DataCategoriesFarmType;
}
export const ButtonComponent = ({ handleOnClickEdit, handleOnClickDelete, handleOnClickRecycle, record }: ButtonComponentProps) => {
	return (
		<Space>
			<Button onClick={() => handleOnClickEdit(record)} style={{ background: OBJ_COLOR.SUCCESS }} type="primary">
				Edit
			</Button>
			<Button onClick={() => handleOnClickDelete(record)} style={{ background: OBJ_COLOR.DANGER }} type="primary">
				Delete
			</Button>
			<Button onClick={() => handleOnClickRecycle(record)} style={{ background: OBJ_COLOR.INFO }} type="primary">
				Recycle this farm
			</Button>
		</Space>
	);
};
