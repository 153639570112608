import { api, providesList } from ".";
import qs from "query-string";

const url = "/users";

const userApi = api.injectEndpoints({
	endpoints: (build) => ({
		getUsers: build.query<UserPage, Record<string, any> | undefined>({
			query: (query) => {
				if (query) {
					return `${url}?${qs.stringify(query)}`;
				}
				return url;
			},
			providesTags: (result) => providesList(result?.items, "users"),
		}),
		createUsers: build.mutation<void, Record<string, any>>({
			query: (data) => {
				return {
					url,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: (result, error, { id }) => {
				return error ? [] : [{ type: "users", id }];
			},
		}),
		updateUsers: build.mutation<void, Record<string, any>>({
			query: (body) => {
				const { id } = body;
				return {
					url: `${url}/edit/${id}`,
					method: "PUT",
					body,
				};
			},
			invalidatesTags: (result, error, { id }) => {
				return error ? [] : [{ type: "users", id }];
			},
		}),
		updateChangePassword: build.mutation<void, Record<string, any>>({
			query: (body) => {
				const { id } = body;
				return {
					url: `${url}/change-password/${id}`,
					method: "PUT",
					body,
				};
			},
		}),
		deleteUsers: build.mutation<void, number | undefined>({
			query: (id) => {
				return {
					url: `${url}/${id}`,
					method: "DELETE",
				};
			},
			invalidatesTags: (result, error) => {
				return error ? [] : [{ type: "users", id: "LIST" }];
			},
		}),
		getUserInfo: build.query<User, number>({
			query: (id) => `${url}/${id}`,
		}),
	}),
});
export const { useGetUserInfoQuery, useGetUsersQuery, useUpdateUsersMutation, useCreateUsersMutation, useDeleteUsersMutation, useUpdateChangePasswordMutation } = userApi;
