import { Menu, Space } from "antd";
import { PATHS } from "commons/constants";
import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "store";

type Mode = "horizontal" | "inline" | "vertical";

interface NavLinkProps {
	mode?: Mode;
	setIsVisible: (boolean: boolean) => void;
}

export const NavLink = (props: NavLinkProps): JSX.Element => {
	const { mode = "horizontal", setIsVisible } = props;
	const user = useAppSelector((state) => state.user);
	const location = useLocation();
	const { pathname } = location;

	const newPath = useMemo(() => {
		if (user?.role === "Staff") return PATHS.filter((path) => path.role === "staff" || path.role === "sale");
		else if (user?.role === "Sale") return PATHS.filter((path) => path.role === "sale");
		else return PATHS;
	}, [user]);

	const items = useMemo(() => {
		const URL = newPath.map((item, index) => ({
			key: index,
			label: item.children ? (
				<Space>{item.name}</Space>
			) : (
				<Link onClick={() => setIsVisible(false)} to={item.path}>
					{item.name}
				</Link>
			),
			children: item.children?.map((_, j) => {
				const subKey = index * 4 + j + 1;
				return {
					key: subKey,
					label: (
						<Link onClick={() => setIsVisible(false)} to={`${item.path}/${_.path}`}>
							{_.name}
						</Link>
					),
				};
			}),
		}));
		return [...URL];
	}, [newPath, setIsVisible]);

	const defaultSelectedKeys = useMemo(() => newPath.findIndex((item) => pathname.indexOf(item.path) !== -1).toString(), [newPath, pathname]);
	return (
		<div style={{}}>
			<Menu style={{ border: 0 }} theme="light" mode={mode} defaultSelectedKeys={[defaultSelectedKeys]} selectedKeys={[defaultSelectedKeys]} items={items} />
		</div>
	);
};
