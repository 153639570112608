import { EyeOutlined } from "@ant-design/icons";
import { Button, MenuProps, Skeleton, Table, TableColumnsType, Tag, Typography } from "antd";
import { useGetSalesOrderDetailQuery } from "api/sales-order/sales-order-api";
import { OBJ_COLOR } from "commons/constants";
import { numberFormat } from "commons/constants/util";
import useFormToggle from "hooks/useFormToggle";
import { useState } from "react";
import HistoryScaleDrawer from "./HistoryScaleDrawer";

interface SalesOrderDetailTableProps {
	salesOrderId: number;
}

const SalesOrderDetailTable = ({ salesOrderId }: SalesOrderDetailTableProps) => {
	const { open, onOpen, onClose } = useFormToggle();
	const [salesOrderDetailId, setSalesOrderDetailId] = useState<number>(0);

	const { data: dataSalesOrderDetail, isFetching } = useGetSalesOrderDetailQuery(salesOrderId, { skip: !salesOrderId, refetchOnMountOrArgChange: true });

	const renderItems = (salesOrderDetailId: number) => {
		const items: MenuProps["items"] = [
			{
				key: "1",
				label: <Typography.Text style={{ color: OBJ_COLOR.SUCCESS }}>Xem chi tiết</Typography.Text>,
				icon: <EyeOutlined style={{ color: OBJ_COLOR.SUCCESS }} />,
				onClick: () => {
					setSalesOrderDetailId(salesOrderDetailId);
					onOpen();
				},
			},
		];

		return items;
	};

	const columns: TableColumnsType<SalesOrderDetail> = [
		{
			title: "STT",
			dataIndex: "key",
			ellipsis: true,
		},
		{
			title: "Mã SO detail",
			dataIndex: "id",
			ellipsis: true,
			render: (id: number) => (
				<Typography.Text type="success" strong>
					{id}
				</Typography.Text>
			),
		},
		{
			title: "Mã con giống",
			dataIndex: "itemCode",
			ellipsis: true,
		},
		{
			title: "Tên con giống",
			dataIndex: "itemName",
			ellipsis: true,
		},
		{
			title: "Loại",
			dataIndex: "chickenType",
			ellipsis: true,
			render: (value: number) => <Tag color={`${value === 1 ? "#2db7f5" : "#87d068"}`}>Loại {value}</Tag>,
		},
		{
			title: "Số lượng trống",
			dataIndex: "maleQuantity",
			ellipsis: true,
			render: (value: number) => numberFormat(value),
		},
		{
			title: "Số lượng mái",
			dataIndex: "femaleQuantity",
			ellipsis: true,
			render: (value: number) => numberFormat(value),
		},
		{
			title: "Số lượng xô",
			dataIndex: "noneQuantity",
			ellipsis: true,
			render: (value: number) => numberFormat(value),
		},
		{
			title: "Tổng số lượng thực tế",
			dataIndex: "totalQty",
			ellipsis: true,
			render: (value: number) => numberFormat(value),
		},
		{
			title: "Tổng trọng lượng thực tế",
			dataIndex: "totalWeight",
			ellipsis: true,
			render: (value: number) => numberFormat(value),
		},
		{
			title: "Trạng thái",
			dataIndex: "status",
			ellipsis: true,
			fixed: "right",
			render: (value: number) => <Tag color={value === 0 ? "warning" : value === 1 ? "success" : "red"}>{value === 0 ? "Chưa cân" : value === 1 ? "Đã cân" : "Huỷ"}</Tag>,
		},
		{
			dataIndex: "id",
			ellipsis: true,
			fixed: "right",
			render: (id: number) => (
				<Button
					type="text"
					style={{ cursor: "pointer" }}
					onClick={() => {
						setSalesOrderDetailId(id);
						onOpen();
					}}
					icon={<EyeOutlined style={{ color: OBJ_COLOR.SUCCESS }} />}>
					<Typography.Text style={{ color: OBJ_COLOR.SUCCESS }}>Xem chi tiết</Typography.Text>
				</Button>
			),
		},
	];

	return (
		<Skeleton active loading={isFetching}>
			<Table columns={columns} dataSource={dataSalesOrderDetail?.map((item, key) => ({ ...item, key: key + 1 }))} scroll={{ x: true }} pagination={false} />
			<HistoryScaleDrawer open={open} onClose={onClose} salesOrderId={salesOrderId} salesOrderDetailId={salesOrderDetailId} />
		</Skeleton>
	);
};

export default SalesOrderDetailTable;
