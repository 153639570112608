import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useLazyGetCategoriesQuery } from "api";
import { useExportExcel } from "hooks/useExportExcel";
import { useEffect, useState } from "react";

interface ButtonExportExcelProps {
	dataTypeId: number;
	nameFile: string;
}
export const ButtonExportExcel = ({ dataTypeId, nameFile }: ButtonExportExcelProps) => {
	const [isLoadingButton, setIsLoadingButton] = useState(false);
	const [getCategories, { data: DATA }] = useLazyGetCategoriesQuery();
	const { message, exportExcel } = useExportExcel();

	const handleOnClickExport = async () => {
		setIsLoadingButton(true);
		try {
			const DATA = await getCategories({ datatype_id: dataTypeId, pageSize: 1000 }).unwrap();

			const Heading: any = [{ code: "Code", name: "Name", uom: "UOM", active: "Active" }];
			const wscols: any = [{ wch: 15 }, { wch: 40 }, { wch: 8 }, { wch: 5 }];
			const header: any[] = [];

			const dataExport = DATA.items.map((item) => {
				return {
					code: item.code,
					name: item.name,
					uom: item.uom,
					active: item.active ? "x" : "-",
				};
			});
			exportExcel({ Heading, wscols, header, dataExport, nameFile });
		} catch (error) {}
		setIsLoadingButton(false);
	};

	return (
		<Button onClick={handleOnClickExport} loading={isLoadingButton} ghost type="primary" icon={<DownloadOutlined />}>
			Export
		</Button>
	);
};
