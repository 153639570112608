import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { useCreateImportDataMutation } from "api";
import { useImportExcel } from "hooks/useImportExcel";
import { useEffect, useState } from "react";
import { useAppSelector } from "store";
import styled from "styled-components";

const BtnLabel = styled.label`
	border-width: 1px;
	border-color: #45b148;
	border-style: solid;
	background: transparent;
	min-width: 120px;
	border-radius: 7px;
	padding: 4px 24px;
	color: #45b148;
	cursor: pointer;
	display: flex;
	justify-content: center;
	input {
		display: none;
	}
`;

export const ImportTemplate = () => {
	const { dataExcel, message, importExcel } = useImportExcel();
	const [createImportData] = useCreateImportDataMutation();
	const user = useAppSelector((state) => state.user);
	const [spin, setSpin] = useState(false);

	useEffect(() => {
		if (dataExcel.length === 0 && message) {
			setSpin(false);
			return notification.error({
				message: "error",
			});
		}

		if (dataExcel.length === 0) return setSpin(false);

		if (message === "error") {
			setSpin(false);
			return notification.error({
				message: "error",
			});
		}

		const data = { user_id: user?.id, data: dataExcel };

		const FetchAPi = async () => {
			try {
				await createImportData(data).unwrap();

				notification.success({
					message: "Success",
				});
			} catch (e: any) {
				notification.error({
					message: e.data.message,
				});
			}
			setSpin(false);
		};
		FetchAPi();
	}, [message, dataExcel, user, createImportData]);

	const onChange = (event: any) => {
		setSpin(true);
		const { files } = event.target;
		if (files.length === 1) {
			importExcel(files[0], "Tempature", ["farm_code", "farm_name", "doc_date", "source", "datatype_name", "code", "code_name", "quantity", "loss_code"]);
		}
	};

	return (
		<>
			<BtnLabel>
				{spin ? (
					<LoadingOutlined />
				) : (
					<>
						<UploadOutlined style={{ marginRight: 8 }} />
						Upload <br />
						<input id="inputTag" type="file" accept=".xlsx, .xls" onChange={(e) => onChange(e)} />
					</>
				)}
			</BtnLabel>
		</>
	);
};
