import { useNavigate } from "react-router-dom";

import { Button, Card, Col, Divider, Form, Input, notification, Row, Typography } from "antd";
import { useUpdateChangePasswordMutation } from "api";

import { useAppSelector } from "store";
import styled from "styled-components";

const { Title } = Typography;

const WrapperBoxLogin = styled.div`
	height: calc(100vh - 200px);
	display: flex;
	justify-content: center;
	align-content: center;
`;
const CardLoginCustom = styled(Card)`
	box-shadow: rgb(0 0 0 / 16%) 0px 1px 2px -2px, rgb(0 0 0 / 12%) 0px 3px 6px 0px, rgb(0 0 0 / 9%) 0px 5px 12px 4px;
	background-size: cover;
	margin: auto;
	height: 450px;
	width: 400px;
	border-color: transparent;
`;

export const ChangePassword = () => {
	const [updateChangePassword] = useUpdateChangePasswordMutation();
	const navigate = useNavigate();
	const user = useAppSelector((state) => state.user);
	const onFinish = async (values: any) => {
		try {
			await updateChangePassword({ newPassword: values.newPassword, oldPassword: values.oldPassword, id: user?.id }).unwrap();
			navigate("/auth/login");
			notification.success({
				message: "Success",
			});
		} catch (e: any) {
			notification.error({
				message: e.data.message,
			});
		}
	};

	return (
		<WrapperBoxLogin>
			<CardLoginCustom>
				<Row gutter={[0, 8]} justify="center">
					<Col xs={20}>
						<Title style={{ color: "rgb(0 65 36)", marginBottom: 0, textAlign: "center" }} level={4}>
							<span style={{ color: "#40ad48" }}>Change Password</span>
						</Title>
						<Divider />
					</Col>
					<Col xs={20}>
						<Form layout="vertical" name="basic" onFinish={onFinish}>
							<Form.Item required label="Old password" name="oldPassword" rules={[{ required: true, message: "Please input your Old password!" }]}>
								<Input.Password placeholder="Old password" />
							</Form.Item>
							<Form.Item required label="New password" name="newPassword" rules={[{ required: true, message: "Please input your New password!" }]}>
								<Input.Password placeholder="New password" />
							</Form.Item>
							<Form.Item
								style={{ marginBottom: 45 }}
								dependencies={["newPassword"]}
								required
								label="Confirm your new password"
								name="confirmPassword"
								rules={[
									{ required: true, message: "Please input your Confirm password!" },
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue("newPassword") === value) {
												return Promise.resolve();
											}
											return Promise.reject(new Error("Confirm password does not match"));
										},
									}),
								]}>
								<Input.Password placeholder="Confirm password" />
							</Form.Item>
							<Form.Item>
								<Button style={{ width: "100%" }} type="primary" htmlType="submit">
									{/* <Button loading={isLoading} icon={<LoginOutlined />} style={{ width: "100%" }} type="primary" htmlType="submit"> */}
									Change password
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</CardLoginCustom>
		</WrapperBoxLogin>
	);
};
