import qs from "query-string";
import { api } from ".";

const url = "/pig-scale";

const pigScaleApi = api.injectEndpoints({
	endpoints: (build) => ({
		getPigScale: build.query<PigScale[], Record<string, any> | void>({
			query: (query) => {
				if (query) {
					return `${url}?${qs.stringify(query)}`;
				}
				return url;
			},
			providesTags: () => [{ type: "pig-scale", id: "LIST" }],
		}),
		deletePigScaleByFarmId: build.mutation<void, string>({
			query: (farmId) => {
				return {
					url: `${url}/${farmId}`,
					method: "DELETE",
				};
			},
			invalidatesTags: (_, error) => (error ? [] : [{ type: "pig-scale", id: "LIST" }]),
		}),
	}),
});
export const { useGetPigScaleQuery, useDeletePigScaleByFarmIdMutation } = pigScaleApi;
