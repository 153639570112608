import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, notification, Space } from "antd";
import { useDeleteCategoriesMutation, useGetCategoriesQuery } from "api";
import { OBJ_COLOR } from "commons/constants";
import { CardComponent } from "components";
import { ButtonExportExcel, CategoriModel, TableCategories } from "pages/categories/components";
import { useEffect, useMemo, useState } from "react";
const { confirm } = Modal;

interface Pagination {
	current: number;
	pageSize: number;
	total: number;
	showSizeChanger: boolean;
}

export const ChickenLossList = () => {
	const [visible, setVisible] = useState(false);
	const [dataModal, setDataModal] = useState<DataCategoriesType | undefined>();
	const [titleModal, setTitleModal] = useState("");
	const [idCategories] = useState(4);
	const [param, setParam] = useState({ pageNumber: 1, pageSize: 10 });
	const [pagination, setPagination] = useState<Pagination>();
	const [deleteCategories] = useDeleteCategoriesMutation();

	const paramApi = useMemo(() => {
		const query = {
			...param,
			datatype_id: 4,
		};
		return query;
	}, [param]);

	const { data: categories, isLoading, isFetching } = useGetCategoriesQuery(paramApi);

	useEffect(() => {
		if (!categories) return;

		setPagination({
			current: param.pageNumber,
			pageSize: param.pageSize,
			total: categories.totalCount,
			showSizeChanger: true,
		});
	}, [categories, param]);

	const handleOnChangePagination = (value: any) => {
		setParam({ pageNumber: value.current, pageSize: value.pageSize });
	};

	const handleOnClickEdit = (value: DataCategoriesType) => {
		setDataModal(value);
		setVisible(true);
		setTitleModal("Edit Feed");
	};
	const handleOnClickCreate = () => {
		setVisible(true);
		setTitleModal("Create Feed");
		setDataModal(undefined);
	};
	const showDeleteConfirm = (value: DataCategoriesType) => {
		confirm({
			title: "Delete ?",
			icon: <ExclamationCircleFilled />,
			content: "Are you sure you want to delete this item?",
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk() {
				const FetchApi = async () => {
					try {
						await deleteCategories(value.id).unwrap();
						notification.success({
							message: "Success",
						});
					} catch (e: any) {
						notification.error({
							message: e.data.message,
						});
					}
				};
				FetchApi();
			},
		});
	};

	return (
		<>
			<CardComponent
				title={"Chicken Loss Management"}
				extra={
					<Space>
						<ButtonExportExcel dataTypeId={idCategories} nameFile={"categories-chicken-loss"} />
						<Button onClick={handleOnClickCreate} style={{ background: OBJ_COLOR.SUCCESS }} type="primary">
							Create new
						</Button>
					</Space>
				}>
				<TableCategories
					dataSource={categories?.items || []}
					handleOnClickEdit={handleOnClickEdit}
					handleOnClickDelete={showDeleteConfirm}
					loading={isLoading || isFetching}
					pagination={pagination}
					handleOnChangePagination={handleOnChangePagination}
				/>
				<CategoriModel visible={visible} setVisible={setVisible} dataModal={dataModal} title={titleModal} datatype_id={idCategories} />
			</CardComponent>
		</>
	);
};
