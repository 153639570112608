import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Input, Modal, notification, Space } from "antd";
import { useDeleteUsersMutation, useGetUsersQuery } from "api";
import { OBJ_COLOR } from "commons/constants";
import { CardComponent } from "components";
import { useDebounce } from "hooks/useDebounce";
import { useEffect, useMemo, useState } from "react";
import { AssignFarmDrawer, TableUser, UserModal } from "./components";

const { confirm } = Modal;
interface Pagination {
	current: number;
	pageSize: number;
	total: number;
	showSizeChanger: boolean;
}
export const User = () => {
	const [visible, setVisible] = useState(false);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [idUser, setIdUser] = useState(0);
	const [dataModal, setDataModal] = useState<User | undefined>();
	const [titleModal, setTitleModal] = useState("");
	const [param, setParam] = useState({ pageNumber: 1, pageSize: 10 });
	const [search, setSearch] = useState<string>("");
	const [pagination, setPagination] = useState<Pagination>();
	const debouncedValue = useDebounce(search, 500);
	const [deleteUsers] = useDeleteUsersMutation();

	const paramApi = useMemo(() => {
		const query = {
			...param,
		};
		if (!debouncedValue) return query;

		return {
			...query,
			search: debouncedValue,
		};
	}, [param, debouncedValue]);

	const { data: users, isLoading, isFetching } = useGetUsersQuery(paramApi);

	useEffect(() => {
		if (!users) return;

		setPagination({
			current: param.pageNumber,
			pageSize: param.pageSize,
			total: users.totalCount,
			showSizeChanger: true,
		});
	}, [users, param]);

	const handleOnChangePagination = (value: any) => {
		setParam({ pageNumber: value.current, pageSize: value.pageSize });
	};

	const handleOnClickEdit = (value: User) => {
		setDataModal(value);
		setVisible(true);
		setTitleModal("Edit User");
	};

	const handleOnClickCreate = () => {
		setVisible(true);
		setTitleModal("Add User");
		setDataModal(undefined);
	};

	const handleOnClickAssign = (value: number) => {
		setOpenDrawer(true);
		setIdUser(value);
	};

	const showDeleteConfirm = (value: any) => {
		confirm({
			title: "Delete ?",
			icon: <ExclamationCircleFilled />,
			content: "Are you sure you want to delete this item?",
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk() {
				const FetchApi = async () => {
					try {
						await deleteUsers(value.id).unwrap();
						notification.success({
							message: "Success",
						});
					} catch (e: any) {
						notification.error({
							message: e.data.message,
						});
					}
				};
				FetchApi();
			},
		});
	};
	return (
		<>
			<CardComponent title={"User Management"}>
				<Space style={{ justifyContent: "space-between", display: "flex", marginBottom: 20, width: "100%" }}>
					<Input placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
					<Space>
						<Button onClick={handleOnClickCreate} icon={<PlusOutlined />} style={{ background: OBJ_COLOR.SUCCESS }} type="primary">
							Create new
						</Button>
					</Space>
				</Space>

				<TableUser
					pagination={pagination}
					handleOnChangePagination={handleOnChangePagination}
					isLoading={isLoading || isFetching}
					users={users?.items}
					handleOnClickEdit={handleOnClickEdit}
					handleOnClickAssign={handleOnClickAssign}
					showDeleteConfirm={showDeleteConfirm}
				/>
				<UserModal visible={visible} setVisible={setVisible} dataModal={dataModal} title={titleModal} />
				<AssignFarmDrawer visible={openDrawer} setVisible={setOpenDrawer} user_id={idUser} />
			</CardComponent>
		</>
	);
};
