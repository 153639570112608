import { api, providesList } from ".";
import qs from "query-string";

const url = "/detail-data";
// https://api-staging.linkfarm.com.vn/detail-data/?pageSize=20&pageNumber=1&exportExcel=1
const detailDataApi = api.injectEndpoints({
	endpoints: (build) => ({
		getFarmList: build.query<FarmListPage, Record<string, any> | undefined>({
			query: (query) => {
				if (query) {
					return `${url}/?${qs.stringify(query)}`;
				}
				return url;
			},
			providesTags: (result) => providesList(result?.items, "farm-list"),
		}),
		getFarmReportExport: build.query<FarmReportExport[], Record<string, any> | undefined>({
			query: (query) => {
				if (query) {
					return `${url}/export?${qs.stringify(query)}`;
				}
				return url;
			},
		}),
	}),
});
export const { useLazyGetFarmReportExportQuery, useGetFarmListQuery, useLazyGetFarmListQuery } = detailDataApi;
