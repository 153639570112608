import { Badge, Button, Col, DatePicker, Form, Input, Row, Select, Table } from "antd";
import { useGetDetailSellQuery, useGetFarmListQuery, useGetFarmsReportQuery, useLazyGetDetailSellQuery, useLazyGetFarmListQuery } from "api";
import { OBJ_COLOR } from "commons/constants";
import { CardComponent } from "components";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import { useExportExcel } from "hooks/useExportExcel";
import moment from "moment";
import numeral from "numeral";
import { useEffect, useMemo, useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";

interface Pagination {
	current: number;
	pageSize: number;
	total: number;
	showSizeChanger: boolean;
}

export const SellReporting = () => {
	const [form] = Form.useForm();
	const [param, setParam] = useState({ pageNumber: 1, pageSize: 20 });
	const [search, setSearch] = useState({});
	const [pagination, setPagination] = useState<Pagination>();
	const { data: listFarm } = useGetFarmsReportQuery(undefined);
	const [isLoadingButton, setIsLoadingButton] = useState(false);
	const { message, exportExcel } = useExportExcel();
	const [getDetailSell] = useLazyGetDetailSellQuery();

	const paramApi = useMemo(() => {
		const query = {
			...param,
		};
		if (!search) return query;

		return {
			...query,
			...search,
		};
	}, [param, search]);

	const { data: DATA, isLoading, isFetching } = useGetDetailSellQuery(paramApi);

	useEffect(() => {
		if (!DATA) return;
		setPagination({
			current: param.pageNumber,
			pageSize: param.pageSize,
			total: DATA.totalCount,
			showSizeChanger: true,
		});
	}, [DATA, param]);

	const handleOnChangePagination = (value: any) => {
		setParam({ pageNumber: value.current, pageSize: value.pageSize });
	};
	const handleReset = () => {
		setSearch({});
		setParam((prev) => ({ ...prev, pageNumber: 1 }));
		form.resetFields();
	};

	const onFinish = (value: any) => {
		let paramSearch = Object.fromEntries(Object.entries(value).filter(([key, index]) => index));

		if (Object.prototype.hasOwnProperty.call(paramSearch, "from_date")) {
			paramSearch = { ...paramSearch, from_date: dayjs(paramSearch.from_date as string).format("YYYY-MM-DD") };
		}
		if (Object.prototype.hasOwnProperty.call(paramSearch, "to_date")) {
			paramSearch = { ...paramSearch, to_date: dayjs(paramSearch.to_date as string).format("YYYY-MM-DD") };
		}
		setSearch(paramSearch);
		setParam((prev) => ({ ...prev, pageNumber: 1 }));
	};

	const handleOnClickExport = async () => {
		setIsLoadingButton(true);

		try {
			const dataExport = await getDetailSell({ ...search, pageNumber: 1, pageSize: 10000 }).unwrap();

			const Heading: any = [
				{
					farm_code: "Farm Code",
					farm_name: "Farm Name",
					doc_date: "Doc Date",
					itemCode: "Code",
					itemName: "Name",
					itemType: "Type",
					gender: "Gender",
					itemUnit: "UOM",
					quantity: "Quantity",
					weight: "Weight",
					username: "User",
					created_date: "System date",
					id: "ID",
				},
			];
			const wscols: any = [{ wch: 8 }, { wch: 20 }, { wch: 10 }, { wch: 12 }, { wch: 28 }, { wch: 6 }, { wch: 6 }, { wch: 6 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 10 }];
			const header: any[] = [];
			const data = dataExport.items.map((item, key) => {
				return {
					farm_code: item.farm_code,
					farm_name: item.farm_name,
					doc_date: dayjs(item.doc_date).format("DD/MM/YYYY"),
					itemCode: item.itemCode,
					itemName: item.itemName,
					itemType: item.itemType,
					gender: item.gender,
					itemUnit: item.itemUnit,
					quantity: item.qty,
					weight: item.weight,
					username: item.username,
					created_date: dayjs(item.created_date).format("DD/MM/YYYY"),
					id: item.master_id,
				};
			});
			exportExcel({ Heading, wscols, header, dataExport: data, nameFile: "sell-report" });
		} catch (error) {}
	};

	useEffect(() => {
		if (message !== "Success") return;

		setIsLoadingButton(false);
	}, [message]);
	return (
		<CardComponent
			title={"Lịch sử bán"}
			extra={
				<Button loading={isLoadingButton} type="primary" onClick={handleOnClickExport} icon={<DownloadOutlined />}>
					Export
				</Button>
			}>
			<Form name="basic" form={form} onFinish={onFinish} style={{ width: "100%" }} layout="vertical">
				<Row gutter={[16, 0]} align="bottom">
					<Col xs={12} xl={5}>
						<Form.Item label="Tên trại" name="farm_code">
							<Select
								showSearch
								style={{ width: "100%" }}
								options={listFarm?.map((item, key) => ({ key: key, label: `${item?.farm_code} ${item?.farm_name ? `- ${item?.farm_name}` : ""} `, value: item.farm_code }))}
								allowClear
								placeholder="--- Chọn trại ---"
							/>
						</Form.Item>
					</Col>
					<Col xs={12} xl={5}>
						<Form.Item label="Từ ngày" name="from_date">
							<DatePicker format={"DD-MM-YYYY"} style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col xs={12} xl={5}>
						<Form.Item label="Đến ngày" name="to_date">
							<DatePicker format={"DD-MM-YYYY"} style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col xs={12} xl={5}>
						<Form.Item>
							<Button style={{ width: "100%" }} type="primary" htmlType="submit">
								Tìm kiếm
							</Button>
						</Form.Item>
					</Col>
					<Col xs={12} xl={4}>
						<Form.Item>
							<Button style={{ width: "100%", background: OBJ_COLOR.WARNING }} type="primary" onClick={handleReset}>
								Đặt lại
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			<Table
				onChange={handleOnChangePagination}
				pagination={pagination}
				dataSource={DATA?.items.map((item, key) => ({ ...item, key: key + 1 }))}
				loading={isLoading || isFetching}
				scroll={{ x: true }}
				style={{ whiteSpace: "nowrap" }}
				// expandable={{
				// 	expandedRowRender: (record) => <TableReportingExpanded id={record.id} />,
				// }}
			>
				<Table.Column<SellReportTable>
					title="Mã trại"
					render={(_, record) => {
						return record.farm_code;
					}}
				/>
				<Table.Column<SellReportTable>
					title="Tên trại"
					render={(_, record) => {
						return record.farm_name;
					}}
				/>
				<Table.Column<SellReportTable>
					title="Ngày báo cáo"
					render={(_, record) => {
						return record.doc_date;
					}}
				/>
				<Table.Column<SellReportTable>
					title="Mã"
					render={(_, record) => {
						return record.itemCode;
					}}
				/>
				<Table.Column<SellReportTable>
					title="Tên"
					render={(_, record) => {
						return record.itemName;
					}}
				/>
				<Table.Column<SellReportTable>
					title="Loại"
					render={(_, record) => {
						return record.itemType;
					}}
				/>
				<Table.Column<SellReportTable>
					title="Giới tính"
					render={(_, record) => {
						return record.gender;
					}}
				/>
				<Table.Column<SellReportTable>
					title="ĐVT"
					render={(_, record) => {
						return record.itemUnit;
					}}
				/>
				<Table.Column<SellReportTable>
					title="Số lượng"
					render={(_, record) => {
						return numeral(record.qty).format("0,0");
					}}
				/>
				<Table.Column<SellReportTable>
					title="Trọng lượng	"
					render={(_, record) => {
						return numeral(record.weight).format("0,0");
					}}
				/>
				<Table.Column<SellReportTable>
					title="Người tạo"
					render={(_, record) => {
						return record.username;
						// return record?.username;
					}}
				/>
				<Table.Column<SellReportTable>
					title="Ngày hệ thống"
					render={(_, record) => {
						return moment(record.created_date).format("DD/MM/YYYY HH:mm");
					}}
				/>
				<Table.Column<SellReportTable>
					title="ID"
					render={(_, record) => {
						return record.master_id;
					}}
				/>
			</Table>
		</CardComponent>
	);
};
