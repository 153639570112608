import { Checkbox, Form, Input, Modal, Select, Space, notification } from "antd";
import { useCreateUsersMutation, useUpdateUsersMutation } from "api";
import { useEffect, useState } from "react";

interface ReportModalProps {
	visible: boolean;
	dataModal?: User;
	setVisible: (visible: boolean) => void;
	title: string;
}
export const UserModal = ({ title, visible, setVisible, dataModal }: ReportModalProps) => {
	const [form] = Form.useForm();
	const [updateUsers, { isLoading }] = useUpdateUsersMutation();
	const [createUsers, { isLoading: LOADING }] = useCreateUsersMutation();
	const [role, setRole] = useState("");

	useEffect(() => {
		if (!visible) return;

		form.resetFields();

		if (dataModal) {
			form.setFieldsValue({
				fullname: dataModal.fullname,
				email: dataModal.email,
				phone: dataModal.phone,
				role: dataModal.role,
				active: dataModal.active,
				area: dataModal.area || undefined
			});
			setRole(dataModal.role)
		}
	}, [visible, form, dataModal]);

	const handleOk = () => {
		form.submit();
	};

	const onFinish = async (value: any) => {
		try {
			if (dataModal) {
				const data = { ...value, id: dataModal?.id };
				await updateUsers(data).unwrap();
			} else {
				const active = !value.active ? false : value.active;
				await createUsers({ ...value, active }).unwrap();
			}
			setVisible(false);
			notification.success({
				message: "Success",
			});
		} catch (e: any) {
			notification.error({
				message: e.data.message,
			});
		}
	};

	return (
		<Modal width={650} maskClosable={false} title={title} open={visible} onOk={handleOk} okText="Save" onCancel={() => setVisible(false)} confirmLoading={isLoading || LOADING}>
			<Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
				<Form.Item label="Fullname" name="fullname" rules={[{ required: true, message: "Please input your fullname!" }]}>
					<Input placeholder="Please enter fullname" />
				</Form.Item>
				{title.includes("Add") && (
					<>
						<Form.Item label="Username" name="username" rules={[{ required: true, message: "Please input your username!" }]}>
							<Input placeholder="Please enter username" />
						</Form.Item>
						<Form.Item label="Password" name="password" rules={[{ required: true, message: "Please input your password!" }]}>
							<Input.Password placeholder="Please enter password" />
						</Form.Item>
					</>
				)}
				<Form.Item label="Role" name="role" rules={[{ required: true, message: "Please select your role!" }]}>
					<Select
						onChange={(value) => setRole(value)}
						style={{ width: "100%" }}
						options={[
							{ value: "Staff", label: "Staff" },
							{ value: "Accountant", label: "Accountant" },
							{ value: "Administrator", label: "Administrator" },
							{ value: "Sale", label: "Sale" },
						]}
						allowClear
						placeholder="---Seclect Role---"
					/>
				</Form.Item>
				<Form.Item label="Email" name="email" rules={[{ required: true, message: "Please input your email!" }]}>
					<Input placeholder="Please enter email" />
				</Form.Item>
				<Form.Item label="Phone" name="phone" rules={[{ required: true, message: "Please input your phone!" }]}>
					<Input placeholder="Please enter phone" />
				</Form.Item>
				{
					role === "Sale" && <Form.Item label="Area" name="area">
						<Select
							style={{ width: "100%" }}
							options={[
								{ value: "Miền Nam", label: "Miền Nam" },
								{ value: "Miền Bắc", label: "Miền Bắc" },
							]}
							allowClear
							placeholder="---Seclect Area---"
						/>
					</Form.Item>
				}

				<Space align="baseline" style={{ marginLeft: 10 }}>
					Active
					<Form.Item name="active" valuePropName="checked">
						<Checkbox />
					</Form.Item>
				</Space>
			</Form>
		</Modal>
	);
};
